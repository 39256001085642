import {
  CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {Card, Col, message, Row, Spin, Typography} from 'antd';
import Button from 'antd/lib/button';
import {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {Context} from '../../context/CommonContext';
import {getActualPrice} from '../../utils/CommonUtils';
import {DisplayText} from '../Common/DisplayText/DisplayText';
import {
  IPaymentFormProps,
  IPaymentFormState,
  IPaymentLocationStateProps,
} from './interface';
import {paySubscription} from './PaymentService';
import {getBillingDetails, getCreateCustomerDetails} from './PaymentUtils';
const {Title, Text} = Typography;
const PaymentForm = (props: IPaymentFormProps) => {
  //Remove PriceId
  const intl = useIntl();
  const navigate = useNavigate();
  const commonData = useContext(Context);
  const {
    contactData,
    contactAPIData,
    subscriptionData,
    setupIntent,
    paymentIntent,
    token,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const billingDetails = getBillingDetails(contactData);
  const accountData = commonData.accountData;
  const stripeApiKeyData = commonData.stripeApiKeyData;
  const location = useLocation();
  const locationData = location.state as IPaymentLocationStateProps;

  const {
    contactFormData,
    selectedProduct,
    selectedProducts,
    foldCustAccountUuid,
  } = locationData;
  const [paymentForm, setPaymentForm] = useState<IPaymentFormState>({
    isLoading: false,
  });

  const handleTiered = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setPaymentForm((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const card = elements.getElement(CardElement);
    if (card) {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: card,
        billing_details: {
          name: billingDetails.name,
        },
      });

      if (paymentMethod.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(paymentMethod);
        message.error(
          intl.formatMessage({
            id: 'apiErrorMsg',
          }),
        );
        setPaymentForm((prev) => {
          return {
            ...prev,
            isLoading: false,
          };
        });
      } else {
        setPaymentForm((prev) => {
          return {
            ...prev,
            isLoading: true,
          };
        });

        let subscriptionPlanList = [];
        for (const price of selectedProducts.selectedProduct.prices) {
          const basePrice = {
            plan: price.id,
          };
          subscriptionPlanList.push(basePrice);
        }

        const createCustomerDetails = getCreateCustomerDetails(contactFormData);

        const subscriptionData: any = await paySubscription({
          accountId: accountData.accountId,
          accountUuid: accountData?.accountToSubdomain?.uuid,
          contactId: contactFormData?.id || 0,
          contactUuid: contactFormData?.uuid || '',
          customerDetails: createCustomerDetails,
          foldCustAccountUuid: foldCustAccountUuid,
          subscriptionPlanList: subscriptionPlanList,
          paymentMethodId: paymentMethod.paymentMethod.id,
          couponCode: '',
          token,
        });

        if (subscriptionData.error) {
          // Show error to your customer (for example, payment details incomplete)
          console.log(subscriptionData);
          message.error(
            intl.formatMessage({
              id: 'apiErrorMsg',
            }),
          );
          setPaymentForm((prev) => {
            return {
              ...prev,
              isLoading: false,
            };
          });
        } else {
          setPaymentForm((prev) => {
            return {
              ...prev,
              isLoading: true,
            };
          });
          navigate('/order-complete', {
            replace: true,
            state: {
              subscriptionData: subscriptionData.data.subscription,
              contactData: contactAPIData,
              selectedPriceType: locationData?.selectedPriceType
            },
          });
          console.log(
            'subscriptionData ===== ',
            subscriptionData.data.subscription,
          );
        }
      }
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setPaymentForm((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const paymentConfirmResult: any = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window?.location?.origin + '/#/checkout/payment',
        payment_method_data: {
          billing_details: billingDetails,
        },
        receipt_email: contactData.email,
      },
      redirect: 'if_required',
    });

    if (paymentConfirmResult.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(paymentConfirmResult);
      message.error(
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
      );
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      navigate('/order-complete', {
        replace: true,
        state: {
          payemntData: paymentConfirmResult,
          contactData: contactAPIData,
          subscriptionData: subscriptionData,
          selectedPriceType: locationData?.selectedPriceType
        },
      });
      console.log('paymentConfirmResult ===== ', paymentConfirmResult);
    }
  };

  const oneTimePay = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setPaymentForm((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });
    const paymentConfirmResult: any = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    console.log('here paymentConfirmResult - - - - -', paymentConfirmResult);

    if (paymentConfirmResult.error) {
      console.log(paymentConfirmResult);
      message.error(
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
      );
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    } else {
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      navigate('/order-complete', {
        replace: true,
        state: {
          payemntData: paymentConfirmResult,
          contactData: contactAPIData,
          selectedPriceType: locationData?.selectedPriceType
        },
      });
      console.log('paymentConfirmResult ===== ', paymentConfirmResult);
    }
  };

  const getTieredPaymentInfo = () => {
    const startTingAt =
      selectedProduct?.selectedPrice?.tiers[0]?.unit_amount_decimal / 100;
    const plustPrice =
      selectedProduct?.selectedPrice?.tiers[0]?.flat_amount_decimal / 100;

    const followedBy =
      selectedProduct?.selectedPrice?.tiers[1]?.unit_amount_decimal / 100;
    const pusFollowed =
      selectedProduct?.selectedPrice?.tiers[1]?.flat_amount_decimal / 100;
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: '#f2f2f2',
          borderRadius: 16,
          marginBottom: 16,
        }}>
        <Row>
          <Col>
            <Title level={5}>{intl.formatMessage({id: 'membership'})}</Title>
          </Col>
          <Col>
            <Text
              style={{
                textOverflow: 'ellipsis',
              }}>
              {selectedProduct?.selectedProduct?.name}
            </Text>
          </Col>
          <Col>
            <Text
              style={{
                textOverflow: 'ellipsis',
              }}>
              Starting at ${startTingAt}
              /unit + ${plustPrice}
              /month for {selectedProduct?.selectedPrice?.tiers[0].up_to} units
            </Text>
          </Col>
          <Col>
            <Text
              style={{
                textOverflow: 'ellipsis',
              }}>
              Followed by ${followedBy}
              /unit + ${pusFollowed}
              /month thereafter
            </Text>
          </Col>
        </Row>
      </div>
    );
  };

  const getMembershipPaymentInfo = () => {
    const tempSubscriptionData = subscriptionData;
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: '#f2f2f2',
          borderRadius: 16,
          marginBottom: 16,
        }}>
        <Row>
          <Col>
            <Title level={5}>{intl.formatMessage({id: 'membership'})}</Title>
          </Col>
          <Col>
            {tempSubscriptionData?.latest_invoice?.lines.data.map(
              (line: any) => {
                return (
                  <div>
                    <Text
                      style={{
                        textOverflow: 'ellipsis',
                      }}>
                      {line.plan.metadata.type} {line.description}
                    </Text>
                  </div>
                );
              },
            )}
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Text>{intl.formatMessage({id:'total'})}</Text>
          </Col>
          <Col></Col>
        </Row> */}
        <div>
          <Row>
            <Col>
              <Text>{intl.formatMessage({id: 'amount'})}</Text>
            </Col>
            <Col>
              <Text
                style={{
                  marginLeft: 16,
                  textOverflow: 'ellipsis',
                }}>
                {getActualPrice(tempSubscriptionData?.latest_invoice?.subtotal)}
              </Text>
            </Col>
          </Row>
          {tempSubscriptionData?.latest_invoice?.discount?.coupon?.valid && (
            <Row>
              <Col>
                <Text>{intl.formatMessage({id: 'discountedAmount'})}</Text>
              </Col>
              <Col>
                <Text
                  style={{
                    marginLeft: 16,
                    textOverflow: 'ellipsis',
                  }}>
                  {getActualPrice(tempSubscriptionData?.latest_invoice?.total)}
                </Text>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  };

  const getPaymentIntentInfo = () => {
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: '#f2f2f2',
          borderRadius: 16,
          marginBottom: 16,
        }}>
        <Row>
          <Col>
            <Title level={5}>{intl.formatMessage({id: 'oneTime'})}</Title>
          </Col>
          <Col>
            <div>
              <Text
                style={{
                  textOverflow: 'ellipsis',
                }}>
                {paymentIntent?.metadata?.type} {paymentIntent?.description}
              </Text>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Text>{intl.formatMessage({id:'total'})}</Text>
          </Col>
          <Col></Col>
        </Row> */}
        <div>
          <Row>
            <Col>
              <Text>{intl.formatMessage({id: 'amount'})}</Text>
            </Col>
            <Col>
              <Text
                style={{
                  marginLeft: 16,
                  textOverflow: 'ellipsis',
                }}>
                {getActualPrice(paymentIntent?.amount)}
              </Text>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  if (setupIntent) {
    return (
      <Spin size="large" spinning={paymentForm.isLoading}>
        <Card
          title={<DisplayText textType="Heading" textLocalId="payment" />}
          className="fh-card-box-shadow"
          style={{
            width: 430,
            maxWidth: '90%',
            margin: 'auto',
            marginTop: 12,
            marginBottom: 40,
            overflow: 'scroll',
            height: 570,
          }}>
          {getTieredPaymentInfo()}
          <form onSubmit={handleTiered}>
            <CardElement />
            <Button
              className="fh-membership-subscription-btn"
              style={{
                marginTop: 16,
                borderRadius: 6,
              }}
              type="primary"
              htmlType="submit">
              Buy
            </Button>
          </form>
        </Card>
        <Outlet context={{contactData, contactAPIData, selectedProduct}} />
      </Spin>
    );
  } else if (paymentIntent) {
    return (
      <Spin size="large" spinning={paymentForm.isLoading}>
        <Card
          title={<DisplayText textType="Heading" textLocalId="payment" />}
          className="fh-card-box-shadow"
          style={{
            width: 430,
            maxWidth: '90%',
            margin: 'auto',
            marginTop: 12,
            marginBottom: 40,
            overflow: 'scroll',
            height: 570,
          }}>
          {getPaymentIntentInfo()}
          <form onSubmit={oneTimePay}>
            <PaymentElement
              onChange={(event) => {
                console.log(event);
              }}
              options={{
                defaultValues: {
                  billingDetails: billingDetails,
                },
                wallets: {
                  applePay: 'auto',
                },
              }}
            />
            <Button
              className="fh-membership-subscription-btn"
              style={{
                marginTop: 16,
                borderRadius: 6,
              }}
              type="primary"
              htmlType="submit">
              Buy
            </Button>
          </form>
        </Card>
        <Outlet context={{contactData, contactAPIData, selectedProduct}} />
      </Spin>
    );
  } else {
    return (
      <Spin size="large" spinning={paymentForm.isLoading}>
        <Card
          title={<DisplayText textType="Heading" textLocalId="payment" />}
          className="fh-card-box-shadow"
          style={{
            width: 430,
            maxWidth: '90%',
            margin: 'auto',
            marginTop: 12,
            marginBottom: 40,
            overflow: 'scroll',
            height: 570,
          }}>
          {getMembershipPaymentInfo()}
          <form onSubmit={handleSubmit}>
            <PaymentElement
              onChange={(event) => {
                console.log(event);
              }}
              options={{
                defaultValues: {
                  billingDetails: billingDetails,
                },
                wallets: {
                  applePay: 'auto',
                },
              }}
            />
            <Button
              className="fh-membership-subscription-btn"
              style={{
                marginTop: 16,
                borderRadius: 6,
              }}
              type="primary"
              htmlType="submit">
              Buy
            </Button>
          </form>
        </Card>
        <Outlet context={{contactData, contactAPIData, selectedProduct}} />
      </Spin>
    );
  }
};

export default PaymentForm;
