import React from 'react';
import {IAccountSubdomainMap, IAccountTheme} from '../components/MainPage/IAccountSubDomain';
import {IPatientCapabilityResp} from '../components/MainPage/IPatientCapability';
import {IConfiguration, IStripeAPIKeyData} from './interface';

export interface ICommonContext {
  accountData: IAccountSubdomainMap;
  accountThemeData: IAccountTheme;
  stripeApiKeyData: IStripeAPIKeyData;
  patientCapabilities: IPatientCapabilityResp;
  accountConfigData?: {[index: string]: IConfiguration};
}

export const Context = React.createContext<ICommonContext>({
  accountData: {} as IAccountSubdomainMap,
  stripeApiKeyData: {} as IStripeAPIKeyData,
  accountThemeData: {} as IAccountTheme,
  patientCapabilities: {} as IPatientCapabilityResp,
  accountConfigData: {} as {[index: string]: IConfiguration}
});

export const CommonContext = (props: {
  children: React.ReactNode;
  accountData: IAccountSubdomainMap;
  accountThemeData: IAccountTheme;
  stripeApiKeyData: IStripeAPIKeyData;
  patientCapabilities: IPatientCapabilityResp;
  accountConfigData?: {[index: string]: IConfiguration};
}) => {
  return (
    <Context.Provider
      value={{
        accountData: props?.accountData || {},
        accountThemeData: props?.accountThemeData,
        patientCapabilities: props?.patientCapabilities,
        stripeApiKeyData: props?.stripeApiKeyData || {},
        accountConfigData: props.accountConfigData
      }}>
      {props.children}
    </Context.Provider>
  );
};

export default CommonContext;
