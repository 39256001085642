/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {IntlProvider} from 'react-intl';
import * as RNLocalize from 'react-native-localize';
import messages from '../messages';

type I18nProviderProps = React.PropsWithChildren<{}>;

// getting the language set by the user
const userLocale = RNLocalize.getLocales()[0].languageCode;

const I18nProvider = ({
  children,
  locale = userLocale,
}: I18nProviderProps | any) => {
  return (
    <IntlProvider
      locale={locale}
      textComponent={React.Fragment}
      messages={messages[locale] as any}>
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
