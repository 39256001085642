import {IStripeAPIKeyData} from './../../context/interface';
import {
  getCurrentSubdomain,
  getPublicApiAccessToken,
} from './../../utils/CommonUtils';
import {CONTACT_API_PATH, CRM_PUBLIC_BASE_URL} from '../../constants/UrlConfigs';

import BaseService from '../../services/CommonService/BaseService';
import {IAccountSubdomainMap, IAccountSubdomainMapResp, IAccountThemeResp} from './IAccountSubDomain';
import {IPatientCapabilityResp} from './IPatientCapability';

export const getAccountSubdomainInfoData = (currentSubdomain?: string) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post<IAccountSubdomainMapResp>(
    CRM_PUBLIC_BASE_URL + `/getAccountIdForSubdomain`,
    JSON.stringify({subDomain: currentSubdomain ?? getCurrentSubdomain()}),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const getAccountThemeData = (accountData?: IAccountSubdomainMap) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post<IAccountThemeResp>(
    CRM_PUBLIC_BASE_URL + `/getAccountThemes`,
    JSON.stringify({subDomain: accountData?.subDomain}),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
        'x-hasura-account-id': accountData?.accountId || '',
      },
    },
  );
};

export const getPatientCapability = (accountData: IAccountSubdomainMap) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IPatientCapabilityResp[]>(
    CRM_PUBLIC_BASE_URL + CONTACT_API_PATH.PATIENT_CAPABILITY,
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
        'x-hasura-account-id': accountData?.accountId || '',
        'account-uuid': accountData?.accountToSubdomain?.uuid || '',
        'account-id': accountData?.accountId || '',
      },
    },
  );
};

export const getAccountStripeApiKeyDataFromAPI = () => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post<IStripeAPIKeyData>(CRM_PUBLIC_BASE_URL + `/stripe-api-key`);
};

export const getAccountConfiguration = async (
  accountId: number,
  accountUuid: string,
) => {
  const axios = BaseService.getSharedInstance().axios;
  return await axios.get(CRM_PUBLIC_BASE_URL + `/account_all_configuration`, {
    headers: {
      'account-id': accountId,
      'account-uuid': accountUuid,
    },
  });
};
