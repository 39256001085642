import {PaymentMethodCreateParams} from '@stripe/stripe-js';
import {ICheckoutForm} from '../CheckoutForm/interface';
import {ICreateCustomerDetails} from './interface';

export const getBillingDetails = (
  contactData: ICheckoutForm,
): PaymentMethodCreateParams.BillingDetails => {
  return {
    address: {
      city: contactData?.city || '',
      country: contactData?.country_iso || 'US',
      line1: contactData?.address || '',
      state: contactData?.state || '',
      postal_code: contactData?.zipcode || '',
    },
    phone: contactData?.phoneNumber || '',
    email: contactData?.email,
    name: (contactData?.firstName || '') + ' ' + (contactData?.lastName || ''),
  };
};

export const getCreateCustomerDetails = (
  contactData: ICheckoutForm,
): ICreateCustomerDetails => {
  return {
    address: {
      // city: contactData?.city || '',
      country: contactData?.country_iso || 'US',
      // line1: contactData?.address || '',
      // state: contactData?.state || '',
      postal_code: contactData?.zipcode || '',
    },
    phone: contactData?.phoneNumber || '',
    email: contactData.email,
    name: (contactData?.firstName || '') + ' ' + (contactData?.lastName || ''),
  };
};
