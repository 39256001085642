import React from 'react';
import {FormattedMessage} from 'react-intl';
import {TEXT_TYPE} from './DisplayTextConst';
import {IDisplayText} from './interface';
import {Typography} from 'antd';
const {Title, Text} = Typography;
export const DisplayText = (props: IDisplayText) => {
  const {textLocalId, className} = props;

  const textType = props.textType || TEXT_TYPE.TEXT;
  const getDisplayText = (): JSX.Element => {
    let displayTextElem = (
      <Text strong className={className || ''}>
        <FormattedMessage id={textLocalId} />
      </Text>
    );
    switch (textType) {
      case TEXT_TYPE.TEXT: {
        displayTextElem = (
          <Text strong className={className || ''}>
            <FormattedMessage id={textLocalId} />
          </Text>
        );
        break;
      }
      case TEXT_TYPE.HEADING: {
        displayTextElem = (
          <Title level={3} className={className || ''}>
            <FormattedMessage id={textLocalId} />
          </Title>
        );
        break;
      }
    }
    return displayTextElem;
  };

  return <>{getDisplayText()}</>;
};
