import {getPhoneNumberAndCountryCode, unmaskPhoneNumber} from '../../utils/CommonUtils';
import {ICreateContactWithVerifyOtp, IGetContact} from './../../services/Contact/ContactInterface';
import {ICheckoutForm} from './../CheckoutForm/interface';
export const buildCreateContactWithOtp = (
  contactData: ICheckoutForm,
  otpData?: {
    otp: string;
    otpVerifyContactType: string;
  },
  tagList?: string[],
  showHowDidYouHearAboutUs?: boolean
) => {
  if (contactData?.emailOrPhoneNumber) {
    if (contactData?.emailOrPhoneNumber.includes('@')) {
      contactData.email = contactData?.emailOrPhoneNumber;
    } else if (
      contactData?.emailOrPhoneNumber?.length === 10 &&
      !isNaN(contactData?.emailOrPhoneNumber as any)
    ) {
      contactData.phoneNumber = contactData?.emailOrPhoneNumber;
    }
  }
  if (contactData.phoneNumber) {
    contactData.phoneNumber = unmaskPhoneNumber(contactData.phoneNumber);
  }
  let phoneNumberData = getPhoneNumberAndCountryCode(
    contactData?.phoneNumber || '',
  );

  const finalContactData: ICreateContactWithVerifyOtp = {
    firstName: contactData?.firstName || '',
    lastName: contactData?.lastName || '',
    email: contactData?.email || '',
    birthDate: contactData?.birthDate || '',
    ehrSexAtBirthCode: contactData?.ehrSexAtBirthCode || '',
    zipCode: contactData?.zipcode || '',
    // address: contactData?.address || '',
    phoneNumber: phoneNumberData?.formattedPhoneNumber || '',
    tags: [''],
  };
  if (contactData?.uuid) {
    finalContactData.contactUuid = contactData?.uuid
  }
  if (otpData?.otp) {
    finalContactData.otp = otpData?.otp;
    finalContactData.otpVerifyContactType = otpData?.otpVerifyContactType;
  }
  if (tagList?.length) {
    finalContactData.tags = [...tagList];
  }
  if (showHowDidYouHearAboutUs) {
    finalContactData.howDidYouHearAboutUs = contactData?.howDidYouHearAboutUs
  }
  const contactAPIDataJsonStr = sessionStorage.getItem('contactAPIData');
  let contactAPIData = {} as IGetContact;
  if (contactAPIDataJsonStr) {
    try {
      contactAPIData = JSON.parse(contactAPIDataJsonStr);
      if (contactAPIData?.uuid) {
        finalContactData.contactUuid = contactAPIData.uuid;
      }
      if (contactAPIData?.id) {
        finalContactData.id = contactAPIData.id;
      }
    } catch (error) {
      contactAPIData = {} as IGetContact
    }
  }
  return finalContactData;
};
