import {Result, Spin} from 'antd';
import {useEffect, useState} from 'react';
import CommonContext from '../../context/CommonContext';
import {IStripeAPIKeyData} from '../../context/interface';
import BaseService from '../../services/CommonService/BaseService';
import CommerceCommonService from '../../services/CommonService/CommerceCommonService';
import {getMembershipToken} from '../../utils/CommonUtils';
import {IAccountSubdomainMap, IAccountTheme} from './IAccountSubDomain';
import {IMainPageProps} from './interface';
import {IPatientCapabilityResp} from './IPatientCapability';
import {
  getAccountConfiguration,
  getAccountStripeApiKeyDataFromAPI,
  getAccountSubdomainInfoData,
  getAccountThemeData,
  getPatientCapability,
} from './MainScreenHelper';

const MainScreen = (props: IMainPageProps) => {
  const [mainScreen, setMainScreen] = useState({
    accountData: {} as IAccountSubdomainMap,
    accountThemeData: {} as IAccountTheme,
    patientCapabilities: {} as IPatientCapabilityResp,
    stripeApiKeyData: {} as IStripeAPIKeyData,
    isLoading: true,
    isError: false,
    accountConfigData: {} as any
  });

  const getStripeAccountApiKey = async () => {
    const accountStripeAPIKeyData: any =
      await getAccountStripeApiKeyDataFromAPI().catch((errorData: any) => {
        console.log(errorData);
      });
    return accountStripeAPIKeyData?.data;
  };

  const getAccountInfo = async (currentSubDomain?: string) => {
    const accountSubDomainData = await getAccountSubdomainInfoData().catch(
      (errorData: any) => {
        console.log(errorData);
      },
    );

    if (accountSubDomainData?.data?.accountSubdomainMaps[0].accountId) {
      const tempAccData = accountSubDomainData?.data?.accountSubdomainMaps[0];
      const accountThemeData = await getAccountThemeData(tempAccData).catch(
        (errorData: any) => {
          console.log(errorData);
        },
      );
      const patientCapabilitiesResp = await getPatientCapability(
        tempAccData,
      ).catch((errorData: any) => {
        console.log(errorData);
      });

      const accountData = accountSubDomainData?.data?.accountSubdomainMaps[0];
      const account_id = accountData?.accountId
      const account_uuid = accountData?.accountToSubdomain?.uuid
      let accountConfigObj = {} as any;
      if (account_id && account_uuid) {
        const accountConfigurationResp = await getAccountConfiguration(account_id, account_uuid)
        if (accountConfigurationResp?.data?.length) {
          accountConfigurationResp?.data.forEach((singleConfig: any) => {
            if (singleConfig.code) {
              accountConfigObj[singleConfig.code] = singleConfig;
            }
          });
        }
      }
      CommerceCommonService.getCommerceCommonServiceInstance(accountData);
      const token = getMembershipToken();
      BaseService.getSharedInstance(accountData, token || '');
      const accountStripeAPIKeyData = await getStripeAccountApiKey();
      setMainScreen((prev) => {
        return {
          ...prev,
          accountData: accountData,
          accountThemeData: accountThemeData?.data
            .accountThemes[0] as IAccountTheme,
          patientCapabilities: patientCapabilitiesResp
            ?.data?.[0] as IPatientCapabilityResp,
          stripeApiKeyData: accountStripeAPIKeyData,
          isLoading: false,
          accountConfigData: accountConfigObj
        };
      });
    } else {
      setMainScreen((prev) => {
        return {
          ...prev,
          isLoading: false,
          isError: true,
        };
      });
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  return (
    <>
      {mainScreen.isLoading ? (
        <Spin
          size="large"
          style={{
            top: '46%',
            position: 'fixed',
          }}
        />
      ) : (
        <>
          {mainScreen.isError ? (
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          ) : (
            <CommonContext
              accountData={mainScreen.accountData}
              accountThemeData={mainScreen.accountThemeData}
              patientCapabilities={mainScreen.patientCapabilities}
              stripeApiKeyData={mainScreen.stripeApiKeyData}
              accountConfigData={mainScreen.accountConfigData}
              >
              {props.children}
            </CommonContext>
          )}
        </>
      )}
    </>
  );
};

export default MainScreen;
