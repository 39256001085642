import {
  Button,
  Card,
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Typography,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {COLOR_CONST} from '../../../constants/ThemeColorConst';
import {getObjectObjectFromList} from '../../../utils/CommonUtils';
import {IMembershipCard} from '../interface';
import {getMembershipPeriodStr} from './MemberCardUtils';
import {PRICES_SORT_ORDER} from '../../../constants/StringConst';
import {IStripeProduct} from '../../../services/StripeProductServices/IProduct';
import {getSortedArrayByIntervals} from '../membershipUtils';
const {Text} = Typography;
const MemberShipCard = (props: IMembershipCard) => {
  const intl = useIntl();
  const {membershipData, index, onBuyNowClick} = props;
  const [memberShipCardState, setMemberShipCardState] = useState({
    selectedPriceObj: membershipData.prices[0],
  });
  const sortedPriceList = getSortedArrayByIntervals(membershipData);
  const onPriceChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    const selectedPriceObj = getObjectObjectFromList(
      membershipData.prices,
      'id',
      value,
    );
    setMemberShipCardState((prev) => {
      return {
        ...prev,
        selectedPriceObj: selectedPriceObj,
      };
    });
  };

  const getPriceViewByPriceCount = (priceCount: number) => {
    return (
      <div
        style={{
          minWidth: 0,
          maxWidth: '100%',
          marginTop: 16,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflow:'scroll',
          position: 'relative'
        }}>
        <Radio.Group
          onChange={onPriceChange}
          value={memberShipCardState.selectedPriceObj.id}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 12,
              width: '100%',
            }}>
            {sortedPriceList?.map((singlePriceObj: any, index: any) => {
              if (singlePriceObj.unit_amount) {
                return (
                  <div
                    key={index}
                    style={{
                      background: COLOR_CONST.cardFeatureBgColor,
                      border: 1,
                      borderColor: 'red',
                      padding: 8,
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: 100,
                      borderRadius: 12,
                    }}
                    className='fh-membership-card-radio-wrapper'
                    >
                    <Radio
                      value={singlePriceObj.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: 0,
                        width: '100%',
                      }}>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: '1.3rem',
                          fontWeight: 700,
                          marginBottom: 0,
                          marginTop: 12,
                          justifyContent: 'flex-start',
                          padding: 0,
                          width: '100%',
                        }}>
                        ${singlePriceObj.unit_amount / 100}
                      </div>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: 12,
                          fontWeight: 400,
                          marginBottom: 0,
                          marginTop: 0,
                          justifyContent: 'flex-start',
                          textAlign: 'center',
                          padding: 0,
                        }}>
                        {getMembershipPeriodStr(singlePriceObj)}
                      </div>
                    </Radio>
                  </div>
                );
              } else if (singlePriceObj.tiers) {
                return (
                  <div
                    key={index}
                    style={{
                      background: COLOR_CONST.cardFeatureBgColor,
                      border: 1,
                      borderColor: 'red',
                      padding: 8,
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minWidth: 100,
                      borderRadius: 12,
                    }}>
                    <Radio
                      value={singlePriceObj.id}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        margin: 0,
                        width: '100%',
                      }}>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: '1.3rem',
                          fontWeight: 700,
                          marginBottom: 0,
                          marginTop: 12,
                          justifyContent: 'flex-start',
                          padding: 0,
                          width: '100%',
                        }}>
                        {singlePriceObj.tiers.map((tier: any, index: any) => {
                          if (
                            tier.unit_amount_decimal &&
                            tier.unit_amount_decimal > 0 &&
                            tier.up_to
                          ) {
                            return (
                              <div
                                style={{
                                  color: COLOR_CONST.cardFontColor,
                                  fontSize: '1.3rem',
                                  fontWeight: 700,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  justifyContent: 'flex-start',
                                  textAlign: 'center',
                                  padding: 0,
                                }}>
                                ${tier.unit_amount_decimal / 100} pm up to{' '}
                                {tier.up_to}
                              </div>
                            );
                          } else if (
                            tier.unit_amount_decimal &&
                            tier.unit_amount_decimal > 0 &&
                            !tier.up_to
                          ) {
                            return (
                              <div
                                style={{
                                  color: COLOR_CONST.cardFontColor,
                                  fontSize: '1.3rem',
                                  fontWeight: 700,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  justifyContent: 'flex-start',
                                  textAlign: 'center',
                                  padding: 0,
                                }}>
                                ${tier.unit_amount_decimal / 100} pm thereafter
                              </div>
                            );
                          } else if (tier.flat_amount_decimal && tier.up_to) {
                            return (
                              <div
                                style={{
                                  color: COLOR_CONST.cardFontColor,
                                  fontSize: '1.3rem',
                                  fontWeight: 700,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  justifyContent: 'flex-start',
                                  textAlign: 'center',
                                  padding: 0,
                                }}>
                                ${tier.flat_amount_decimal / 100} upto{' '}
                                {tier.up_to}
                              </div>
                            );
                          } else if (tier.flat_amount_decimal && !tier.up_to) {
                            return (
                              <div
                                style={{
                                  color: COLOR_CONST.cardFontColor,
                                  fontSize: '1.3rem',
                                  fontWeight: 700,
                                  marginBottom: 0,
                                  marginTop: 0,
                                  justifyContent: 'flex-start',
                                  textAlign: 'center',
                                  padding: 0,
                                }}>
                                ${tier.flat_amount_decimal / 100} thereafter
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: 12,
                          fontWeight: 400,
                          marginBottom: 0,
                          marginTop: 0,
                          justifyContent: 'flex-start',
                          textAlign: 'center',
                          padding: 0,
                        }}>
                        {getMembershipPeriodStr(singlePriceObj)}
                      </div>
                    </Radio>
                  </div>
                );
              }
            })}
          </div>
        </Radio.Group>
      </div>
    );
  };
  useEffect(()=> {
    console.log(getSortedArrayByIntervals(membershipData))
  },[])
  return (
    <Card
      style={{
        borderRadius: 16,
        backgroundColor: 'white',
        padding: 16,
        height: 'auto',
      }}>
      <Row style={{padding: 0}}>
        <Title
          style={{
            color: COLOR_CONST.cardTitleColor,
            fontSize: '1.3rem',
            fontWeight: 700,
            marginBottom: 0,
            marginTop: 0,
            justifyContent: 'flex-start',
            textAlign: 'left',
            padding: 0,
            width: '90%',
          }}
          className='fh-membership-card-title'
          >
          {membershipData.name}
        </Title>
      </Row>
      <Col
        style={{
          fontSize: '0.8rem',
          padding: 25,
          paddingTop: 25,
          paddingBottom: 25,
          borderRadius: 8,
          background: COLOR_CONST.cardFeatureBgColor,
          marginTop: 16,
        }}
        className='fh-membership-card-description'
        >
        {membershipData.description}
      </Col>
      {getPriceViewByPriceCount(membershipData.prices.length)}
      <Button
        type="primary"
        onClick={() => {
          onBuyNowClick({
            selectedPrice: memberShipCardState.selectedPriceObj as any,
            selectedProduct: membershipData,
          });
        }}
        block
        style={{
          backgroundColor: COLOR_CONST.titleColor,
          marginTop: 40,
          height: 40,
          borderRadius: 5,
          border: 'none',
        }}
        className='fh-membership-card-btn'
        >
        <Text strong className='fh-membership-card-btn'>{intl.formatMessage({id: 'buyNow'})}</Text>
      </Button>
    </Card>
  );
};
export default MemberShipCard;


