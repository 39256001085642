import {Input} from 'antd';
import React, {forwardRef} from 'react';
import ReactInputMask from 'react-input-mask';

const CustomInput = forwardRef((props: any, ref) => {
  return (
    <ReactInputMask {...props}>
      {(inputProps: any) => {
        return (
          <Input
            {...inputProps}
            ref={ref}
            disabled={props.disabled ? props.disabled : null}
          />
        );
      }}
    </ReactInputMask>
  );
});

export default CustomInput;
