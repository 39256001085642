import {IMlov} from './interface';

export const PERSON_GENDER_LIST: IMlov[] = [
  {
    value: 'Male',
    code: 'M',
  },
  {
    value: 'Female',
    code: 'F',
  },
  {
    value: 'Other',
    code: 'OTH',
  },
  {
    value: 'Unknown',
    code: 'UNK',
  }
];
