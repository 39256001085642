import {IZipCodeSearchResp} from './IZipCodeSearch';
import {
  CONTACT_API_PATH,
  CRM_PUBLIC_BASE_URL,
} from './../../constants/UrlConfigs';
import {CRM_BASE_URL} from '../../constants/UrlConfigs';
import BaseService from '../CommonService/BaseService';
import {ICreateContactWithVerifyOtp, IGetContactResp} from './ContactInterface';
import {getPublicApiAccessToken} from '../../utils/CommonUtils';

export const getContactInformationFromAPI = (params?: {customAttributeId?: string}) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IGetContactResp>(
    CRM_BASE_URL + CONTACT_API_PATH.GET_CONTACT,
    {
      params,
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const sendOTPFromAPI = (otpBodyData: {
  contactType: string;
  contact: string;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post(
    CRM_PUBLIC_BASE_URL + CONTACT_API_PATH.SEND_OTP,
    JSON.stringify(otpBodyData),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const verifyOTPFromAPI = (otpBodyData: {
  otp: string;
  contact: string;
}) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post(
    CRM_BASE_URL + CONTACT_API_PATH.VERIFY_OTP,
    JSON.stringify(otpBodyData),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const createContactWithVerifyOTPFromAPI = (
  otpBodyData: ICreateContactWithVerifyOtp,
) => {
  // Create new contact if new email of phone number
  const bodyData = {...otpBodyData};
  delete bodyData.id;
  delete bodyData.uuid;
  // Create new contact if new email of phone number
  const axios = BaseService.getSharedInstance().axios;
  if (bodyData.otpVerifyContactType === 'phoneNumber') {
    if (!bodyData?.phoneNumber?.includes('+')) {
      if (bodyData.phoneNumber) {
        bodyData.phoneNumber = '+1' + bodyData.phoneNumber;
      }
    }
  }
  return axios.post(
    CRM_PUBLIC_BASE_URL + CONTACT_API_PATH.CREATE_CONTACT_WITH_VERIFY_OTP,
    JSON.stringify(bodyData),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const createContactWithOutVerifyOTPFromAPI = (
  otpBodyData: ICreateContactWithVerifyOtp,
) => {
  const axios = BaseService.getSharedInstance().axios;
  // Create new contact if new email of phone number
  const bodyData = {...otpBodyData};
  delete bodyData.id;
  delete bodyData.uuid;
  // Create new contact if new email of phone number
  return axios.post(
    CRM_PUBLIC_BASE_URL + CONTACT_API_PATH.CREATE_CONTACT_WITHOUT_VERIFY_OTP,
    JSON.stringify(bodyData),
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const searchZipCode = (searchString: string) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IZipCodeSearchResp>(
    CRM_PUBLIC_BASE_URL + '/zipcode?zipcode=' + searchString,
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const getCustomAttributeId = async (params: {code: string}) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get(CONTACT_API_PATH.CUSTOM_ATTRIBUTE_ID + '/' + params?.code)
};