import {Button,Result,Row, Typography} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import React,{useContext, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {ON_OFF_CONFIG_VALUE} from '../../constants/AccountConfigConst';
import {CONFIGURATION_CODE, RECURRING_PRICE_DISPLAY_CODE, REPLACE_VALUE_CONST} from '../../constants/StringConst';
import {COLOR_CONST} from '../../constants/ThemeColorConst';
import {Context} from '../../context/CommonContext';
import {placeAccountNameAndPatientLink} from '../../utils/CommonUtils';
import {getAccountConfigValue, isAccountConfigEnabled} from '../../utils/configUtils';
import MemberShipBackground from '../Membership/MemberShipBackground/MemberShipBackground';
const {Text} = Typography;
const OrderSuccessfullyPlaced = () => {
  const location = useLocation();
  const locationData: any = location.state;
  const commonData = useContext(Context);
  const accountData = commonData.accountData;
  const SessionSubscriptionData =  sessionStorage.getItem('subscriptionData') || ''
  const subscriptionDataJson = JSON.parse(SessionSubscriptionData);
  const isAlreadyPatient = subscriptionDataJson?.data?.contact
  const paymentData = locationData?.paymentData || {};
  const subscriptionData = locationData?.subscriptionData || subscriptionDataJson?.data?.subscription || {};
  const subTitle = `Order number: ${subscriptionData?.latest_invoice?.number}`;
  let titleSubtitleObj =  getAccountConfigValue(
    commonData,
    CONFIGURATION_CODE.ORDER_COMPLETE_TITLE_SUBTITLE_TEXT,
  );
  try {
    titleSubtitleObj = JSON.parse(titleSubtitleObj)
  } catch(err) {
    console.log("json error" ,err);
  }
  let subtitleMessage = placeAccountNameAndPatientLink(
    accountData?.accountToSubdomain?.name,
    titleSubtitleObj.subTitle,
    REPLACE_VALUE_CONST.accountName,
  );
  const goToPatientApp = () => {
    const origin = window.location.origin;
    const patientLink = origin.replace('membership', 'patientapp');
    return `<a class="fh-membership-link-text" href="${patientLink}" target="_blank"> Go to patient Link </a>`
  };
  subtitleMessage = placeAccountNameAndPatientLink(
    goToPatientApp(),
    subtitleMessage,
    REPLACE_VALUE_CONST.patientLink,
  );
  const subTitleInHtml = `<div>${subtitleMessage}</div>`
  sessionStorage.clear();

  const getText = () => {
    if (isAlreadyPatient?.id) {
      return 'with your credentials'
    }else{
      return 'with credentials sent on your email'
    }
  }

  const parseHtml = () => {
    const ele:any = document.getElementById('subTitle');
    if(ele?.innerHTML) {
      ele.innerHTML = subTitleInHtml
    }
  }
  useEffect(() => {
    parseHtml()
  }, [])


  return (
    <MemberShipBackground>
      <Content
        style={{
          height: '100%',
        }}>
        <Result
          style={{color: '#fff'}}
          status="success"
          title={
            <Title
              style={{
                color: COLOR_CONST.descriptionColor,
                fontSize: '2rem',
                fontWeight: 400,
                marginBottom: 0,
                marginTop: 16,
              }}
              className="fh-membership-card-title">
              {placeAccountNameAndPatientLink(
                accountData?.accountToSubdomain?.name,
                titleSubtitleObj.title,
                REPLACE_VALUE_CONST.accountName,
              )}
            </Title>
          }
          subTitle={
            subscriptionData?.latest_invoice?.number ? (
              <Title
                style={{
                  color: COLOR_CONST.titleColor,
                  fontSize: '1rem',
                  fontWeight: 400,
                  marginBottom: 0,
                  marginTop: 16,
                }}
                className="fh-membership-card-title">
                {subTitle}
              </Title>
            ) : null
          }
        />
        {(locationData?.selectedPriceType !==
          RECURRING_PRICE_DISPLAY_CODE.ONE_TIME ||
          titleSubtitleObj.showSubtitleForOneTime === ON_OFF_CONFIG_VALUE.ON) && (
          <Row style={{alignItems: 'center', justifyContent: 'center'}}>
            <Title
              id="subTitle"
              style={{
                color: COLOR_CONST.descriptionColor,
                fontSize: '1rem',
                fontWeight: 400,
                marginBottom: 0,
                marginTop: 16,
                maxWidth: '70%',
                lineHeight: 1.5
              }}
              className="fh-membership-card-title">
              {subTitleInHtml}
              {/* <Link to={goToPatientApp()} target="_blank">
              patient app(click here)
            </Link> */}
              {/* {getText()} */}
            </Title>
          </Row>
        )}
        {/* <Row style={{alignItems: 'center', justifyContent: 'center'}}> */}
        {subscriptionData?.latest_invoice?.hosted_invoice_url ? (
          <Button
            className="fh-membership-common-btn"
            type="primary"
            key="console"
            onClick={() => {
              if (subscriptionData?.latest_invoice?.hosted_invoice_url) {
                window.open(
                  subscriptionData?.latest_invoice?.hosted_invoice_url,
                );
              }
            }}
            style={{borderRadius: 6}}>
            Check Invoice
          </Button>
        ) : null}
        {subscriptionData?.latest_invoice?.invoice_pdf ? (
          <Button
            className="fh-membership-common-btn"
            style={{
              marginLeft: 10,
              marginTop: 10,
              borderRadius: 6,
            }}
            key="buy"
            onClick={() => {
              if (subscriptionData?.latest_invoice?.invoice_pdf) {
                window.open(subscriptionData?.latest_invoice?.invoice_pdf);
              }
            }}>
            Download
          </Button>
        ) : null}
        {/* </Row> */}
      </Content>
    </MemberShipBackground>
  );
};

export default OrderSuccessfullyPlaced;
