export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const STRIPE_PAYMENT_METHOD_TYPE = 'Spree::Gateway::StripeGateway';

export const USER_CART_KEY = 'fh_cart_details_';

export const OTP_ACTION_CODE = {
  MODAL_CLOSE: 'MODAL_CLOSE',
  OTP_VERIFIED_SUCCESS: 'OTP_VERIFIED_SUCCESS',
};

export const HTTP_Status_code = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
};

export type IRecurringIntervalType = 'day' | 'month' | 'week' | 'year';

export const RECURRING_PRICE_CODE: {[index: string]: IRecurringIntervalType} = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

export const RECURRING_PRICE_DISPLAY_STR = {
  ONE_TIME: 'One Time',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: '/month',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
};
export const PRICES_SORT_ORDER = [
  RECURRING_PRICE_CODE.YEAR,
  RECURRING_PRICE_CODE.MONTH,
  RECURRING_PRICE_CODE.DAY,
];
export const DEFAULT_LOGO_URL =
  'https://foldhealth-develop-cms-uploads.s3.us-east-2.amazonaws.com/02a088ac-9a36-4886-b3d8-41ba2c74ba5b/fold_logo_89bba4d19d.png';

export const RECURRING_PRICE_DISPLAY_CODE = {
  ONE_TIME: 'one_time',
};

export const MEMBERSHIP_ACCESS_TOKEN = 'membership-access-token';

export const GENERAL_STRING_CONST = {
  DUPLICATE: 'DUPLICATE',
  ACTIVE_MEMBERSHIP: 'ACTIVE_MEMBERSHIP'
}


export const CONFIGURATION_CODE = {
  EMAIL_PHONE_NUMBER_FIELD: 'EMAIL_PHONE_NUMBER_FIELD',
  HIDE_PATIENT_APP_LINK: 'HIDE_PATIENT_APP_LINK',
  ORDER_COMPLETE_TITLE_SUBTITLE_TEXT: 'ORDER_COMPLETE_TITLE_SUBTITLE_TEXT',
  CUSTOM_FIELD_HOW_DID_YOU_HEAR_ABOUT_US:'CUSTOM_FIELD_HOW_DID_YOU_HEAR_ABOUT_US'
}

export const REPLACE_VALUE_CONST ={
  accountName: /#accountName#/g,
  patientLink: /#patientLink#/g
}

export const HOW_DID_YOU_HEAR_ABOUT_US = 'how_did_you_hear_about_us';
export const HOW_DID_YOU_HEAR_ABOUT_US_CHAR_LIMIT = 254;