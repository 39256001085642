import {
  IPatientCapabilityResp,
  IPossibleValue,
} from '../components/MainPage/IPatientCapability';
import {COLOR_CONST} from '../constants/ThemeColorConst';
import {ICommonContext} from '../context/CommonContext';
import {IConfiguration} from '../context/interface';

export function getEnvVariable() {
  if (window.location.host.includes('localhost')) {
    return 'sandbox';
  } else if (window.location.host.includes('.sandbox.foldhealth.io')) {
    return 'sandbox';
  } else if (window.location.host.includes('.qa.foldhealth.io')) {
    return 'qa';
  } else if (window.location.host.includes('.dev.foldhealth.io')) {
    return 'dev';
  } else if (window.location.host.includes('.foldhealth.io')) {
    return '';
  }
  return 'dev';
}

export const getPublicApiAccessToken = (): string => {
  // Do not add any public api tokens here
  return '';
};

export function evnName() {
  return getEnvVariable().length ? `${getEnvVariable()}.` : '';
}

export const addQueryParamsInApiPath = (
  apiUrl: string,
  queryParams: any,
): string => {
  const queryParamsKeys = Object.keys(queryParams || {});
  for (let index = 0; index < queryParamsKeys.length; index++) {
    const currentKey = queryParamsKeys[index];
    const value = queryParams[currentKey];
    if (index === queryParamsKeys.length - 1) {
      apiUrl += `${currentKey}=${value}`;
    } else {
      apiUrl += `${currentKey}=${value}&`;
    }
  }
  return apiUrl;
};

export const getCommerceSubdomain = (subDomain: string) => {
  const array = subDomain?.split('.');
  const subdomainName = array.length ? array[0] : 'demo';
  return `${subdomainName}.website.${evnName()}foldhealth.io`;
};

export const getCurrentSubdomain = () => {
  if (window?.location?.host?.includes('localhost')) {
    return `testing24.${evnName()}foldhealth.io`;
  }
  let hostURL = window.location.host;
  const replaceAppDomain = ['membership.'];
  replaceAppDomain.forEach((domain) => {
    hostURL = hostURL?.replace(domain, '');
  });
  return hostURL;
};


export const unmaskPhoneNumber = (str: string) => {
  return str.replace(/[^\d]/g, '');
};

export const getMembershipToken = () => {
  const queryParams = new URLSearchParams(
    window?.location?.hash?.split('?')?.[1],
  );
  return queryParams.get('token');
};



export const getQueryParams = () => {
  return window?.location?.hash?.split('?')?.[1];
};

export const getQueryParamValue = (key: string) => {
  return getQueryParams()?.split(`${key}=`)?.[1];
};

export const getPhoneNumberAndCountryCode = (phoneNumber: string) => {
  let formattedPhoneNumber = phoneNumber;
  let phoneNumberCountryCode = '+1';
  if (phoneNumber?.length > 10) {
    const phoneDigitList: any = phoneNumber.match(/[\d]/gi);
    if (phoneDigitList?.length > 10) {
      const listOfDigits = phoneDigitList.splice(-10);
      formattedPhoneNumber = listOfDigits.join('');
      phoneNumberCountryCode = phoneDigitList.join('');
      if (!phoneNumberCountryCode) {
        phoneNumberCountryCode = '+1';
      }
    } else if (phoneDigitList?.length === 10) {
      formattedPhoneNumber = phoneDigitList.join('');
    } else {
      console.log('\nphoneNumber ==== ', phoneNumber);
    }
  }
  return {
    formattedPhoneNumber,
    phoneNumberCountryCode,
  };
};

export const getObjectObjectFromList = (
  list: any[],
  key: string,
  value: string,
) => {
  return list.find((listItem) => {
    return listItem[key] === value;
  });
};

export const getAccSubdomainClassName = () => {
  let host = window.location.host;
  if (window?.location?.host?.includes('localhost')) {
    return `demo-membership-dev-foldhealth-io`;
  }
  return host?.replace(/\./gi, '-');
};

export const getDateToMomentISOString = (dateObj: any): string => {
  return dateObj.toISOString();
};

export const commonDebounce = (funcRef: any, timeout = 300) => {
  let timer: any;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      funcRef.apply(this, args);
    }, timeout);
  };
};

export const getActualPrice = (amount: number) => {
  if (amount) {
    const actualAmt = amount / 100;
    return '$' + actualAmt;
  }
  return amount;
};

export const getSexAtBirthList = (
  patientCapabilities: IPatientCapabilityResp,
) => {
  let genderList = [] as IPossibleValue[];
  if (patientCapabilities?.abilities?.keyAllowedOperations?.birthSex) {
    const birthSexObj =
      patientCapabilities?.abilities?.keyAllowedOperations?.birthSex;
    genderList = birthSexObj.possibleValues;
  }
  return genderList;
};

export const getProductId = () => {
  const queryParams = new URLSearchParams(
    window?.location?.hash?.split('?')?.[1],
  );
  return queryParams.get('productId');
};

export const getProductPlan = () => {
  const queryParams = new URLSearchParams(
    window?.location?.hash?.split('?')?.[1],
  );
  return queryParams.get('plan');
};


export const getBackGroundColorTop = () => {
  if (!window.location.host.includes('zocalo')) {
    return COLOR_CONST.bgColor2
  }
  return COLOR_CONST.bgColor1
}
export const getBackGroundColorBottom = () => {
  if (!window.location.host.includes('zocalo')) {
    return COLOR_CONST.bgColor2
  }
  return 'rgba(0, 0, 0, 0.25)'
}

export const placeAccountNameAndPatientLink = (value: string, text: string, replace: any) => {
  const title = text.replace(
    replace,
    value
);
return title
}
