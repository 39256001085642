import {CRM_BASE_URL} from '../../constants/UrlConfigs';
import BaseService from '../../services/CommonService/BaseService';
import {IPaymentIntentResp} from '../../services/ProductAndServices/interfaces/StripePayment';
import {
  IInitializeProductPurchase,
  IMembershipSubscriptionBody,
  IPaymentRequestBody,
  ISetupIntentBody,
} from './interface';
import {ISubscriptionResp} from './ISubscription';
import {MEMBERSHIP_ACCESS_TOKEN} from '../../constants/StringConst';

export const getPaymentIntent = (paymentBody: IPaymentRequestBody) => {
  const axios = BaseService.getSharedInstance().axios;
  const token = sessionStorage.getItem('token');
  return axios.post<IPaymentIntentResp>(
    CRM_BASE_URL + `/payment/payment-intent`,
    JSON.stringify(paymentBody),
    {
      headers: {
        [MEMBERSHIP_ACCESS_TOKEN]: token || '',
      },
    },
  );
};

export const paySubscription = (
  subscriptionBody: IMembershipSubscriptionBody,
) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.post<ISubscriptionResp>(
    CRM_BASE_URL + `/payment/membership-subscription-checkout`,
    JSON.stringify(subscriptionBody),
    {
      headers: {
        [MEMBERSHIP_ACCESS_TOKEN]: subscriptionBody.token || '',
      },
    },
  );
};

// 'http://localhost:5431/crm-nest'

export const initializeProductPurchase = (
  subscriptionBody: IInitializeProductPurchase,
) => {
  const axios = BaseService.getSharedInstance().axios;
  const token = sessionStorage.getItem('token');
  return axios.post<ISubscriptionResp>(
    CRM_BASE_URL + `/payment/initialize-Product-purchase`,
    JSON.stringify(subscriptionBody),
    {
      headers: {
        [MEMBERSHIP_ACCESS_TOKEN]: token || '',
      },
    },
  );
};

export const getCharge = (Body: any) => {
  const axios = BaseService.getSharedInstance().axios;
  const token = sessionStorage.getItem('token');
  return axios.post<ISubscriptionResp>(
    CRM_BASE_URL + `/payment/get-charge`,
    JSON.stringify(Body),
    {
      headers: {
        [MEMBERSHIP_ACCESS_TOKEN]: token || '',
      },
    },
  );
};

export const setupIntent = (setupIntent: ISetupIntentBody) => {
  const axios = BaseService.getSharedInstance().axios;
  const token = sessionStorage.getItem('token');
  return axios.post<any>(
    CRM_BASE_URL + `/payment/setup-intent`,
    JSON.stringify(setupIntent),
    {
      headers: {
        [MEMBERSHIP_ACCESS_TOKEN]: token || '',
      },
    },
  );
};
