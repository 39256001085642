import {CRM_BASE_URL} from '../../constants/UrlConfigs';
import {getPublicApiAccessToken} from '../../utils/CommonUtils';
import BaseService from '../CommonService/BaseService';
import {ICouponCode} from './ICouponCode';
import {IStripeProductResp, IStripeSubscriptionResp} from './IProduct';

export const getAllMembershipListFromAPI = (params?: {productId?: string | null}) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IStripeProductResp>(CRM_BASE_URL + `/public/product`, {
    headers: {
      // api_access_token: getPublicApiAccessToken(),
    },
    params: params
  });
};

export const getAllBillingListFromAPI = () => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IStripeProductResp>(
    CRM_BASE_URL + `/public/billing-product`,
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};

export const getAllBillingSubscriptionListFromAPI = (
  foldCustAccountUuid: string,
) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<IStripeSubscriptionResp>(
    CRM_BASE_URL + `/stripe/search-billing-charges`,
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
        // 'account-uuid': foldCustAccountUuid,
      },
      params: {
        expand: ['data.plan.product'],
      },
    },
  );
};

export const checkCouponCodeAPI = (couponCode: string) => {
  const axios = BaseService.getSharedInstance().axios;
  return axios.get<ICouponCode>(
    CRM_BASE_URL + `/public/coupon-code?couponCode=` + couponCode,
    {
      headers: {
        // api_access_token: getPublicApiAccessToken(),
      },
    },
  );
};
