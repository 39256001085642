import {PRICES_SORT_ORDER} from "../../constants/StringConst";
import {IStripeProduct} from "../../services/StripeProductServices/IProduct";

export const isEvenNumber = (number: number) => {
  if (number % 2 == 0) {
    return true;
  }
  return false;
};

export const getSortedArrayByIntervals = (membershipData:IStripeProduct) => {
  const sortedArray = membershipData?.prices?.sort((a: any, b: any) => {
    return (
      (b?.recurring?.interval === 'month' &&
        a?.recurring?.interval === 'month' &&
        b?.recurring?.interval_count - a?.recurring?.interval_count) ||
      PRICES_SORT_ORDER.indexOf(a?.recurring?.interval) -
        PRICES_SORT_ORDER.indexOf(b?.recurring?.interval)
    );
  });
    return sortedArray
}
