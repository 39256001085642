import {Card, Image, Layout, Row} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import {useContext} from 'react';
import {DEFAULT_LOGO_URL} from '../../../constants/StringConst';
import {COLOR_CONST} from '../../../constants/ThemeColorConst';
import {Context} from '../../../context/CommonContext';
import {IAccountThemeAdditionalAttr} from '../../MainPage/IAccountSubDomain';
import {getBackGroundColorBottom, getBackGroundColorTop} from '../../../utils/CommonUtils'
const {Meta} = Card;
const MemberShipBackground = (props: any) => {
  const commonData = useContext(Context);
  const accountThemeData = commonData.accountThemeData;
  let additionalAttributes: IAccountThemeAdditionalAttr = accountThemeData.additionalAttributes || '{}' as any;
  if (additionalAttributes) {
    additionalAttributes = JSON.parse(additionalAttributes as any);
  }
  const contentObject = {
    imageUrl: additionalAttributes?.attachment?.logo?.url || DEFAULT_LOGO_URL,
    title: '',
    description:
      '',
  };
  return (
    <Content
      style={{
        height: '100vh',
        overflow: 'scroll',
      }}>
      <Layout style={{background: 'transparent'}}>
        <Layout
          style={{
            height: '100%',
            position: 'fixed',
            width: '100%',
            zIndex: -1,
          }}>
          <Content
            style={{
              height: '50%',
              background: getBackGroundColorTop(),
            }}>
            <Content
              style={{
                background: getBackGroundColorBottom(),
                height: '100%',
              }}></Content>
          </Content>
          <Content style={{height: '50%', background: getBackGroundColorTop()}}>
            {/* Content2 */}
          </Content>
        </Layout>
        <Content style={{width: '90%', margin: 'auto', paddingTop: 100}}>
          <Row
            style={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Image preview={false} width={100} src={contentObject.imageUrl} />
          </Row>
          <Title
            style={{
              color: COLOR_CONST.titleColor,
              fontSize: '1.3rem',
              fontWeight: 700,
              marginBottom: 0,
              marginTop: 16,
            }}>
            {contentObject.title}
          </Title>
          <Title
            style={{
              color: COLOR_CONST.descriptionColor,
              fontSize: '1rem',
              fontWeight: 400,
              marginBottom: 0,
              marginTop: 16,
            }}>
            {contentObject.description}
          </Title>
          {props.children}
        </Content>
      </Layout>
    </Content>
  );
};

export default MemberShipBackground;
