import 'antd/dist/antd.css';
import 'antd/dist/antd.variable.css';
import './App.css';
import {HashRouter} from 'react-router-dom';
import {Route, Routes} from 'react-router-dom';
import Membership from './components/Membership/Membership';
import I18nProvider from './i18n/provider/I18nProvider';
import MainScreen from './components/MainPage/MainPage';
import CheckoutForm from './components/CheckoutForm/CheckoutForm';
import OrderSuccessfullyPlaced from './components/Order/OrderSuccessfullyPlaced';
import {getAccSubdomainClassName} from './utils/CommonUtils';
import './themeCss/all-dev-env.css';
import './themeCss/all-qa-env.css';
import './themeCss/all-sandbox-env.css';
import './themeCss/all-prod-env.css';
import './themeCss/demo.css';
import './themeCss/zocalo-dev.css';
import './themeCss/zocalo-sandbox.css';
import './themeCss/all-prod-env-new-theme.css'
import PaymentFormWrapper from './components/Payment/PaymentFormWrapper';

function App() {
  const accountClassName = getAccSubdomainClassName();
  let defaultClassForTheme = '';
  if (window.location.host.includes('localhost')) {
    defaultClassForTheme = 'all-dev-env';
    if (!window.location.host.includes('zocalo')) {
      defaultClassForTheme = 'all-prod-new-theme';
    }
  } else if (!window.location.host.includes('zocalo')) {
    defaultClassForTheme = 'all-prod-new-theme';
  }  else if (window.location.host.includes('.sandbox.foldhealth.io')) {
    defaultClassForTheme = 'all-sandbox-env';
  } else if (window.location.host.includes('.qa.foldhealth.io')) {
    defaultClassForTheme = 'all-qa-env';
  } else if (window.location.host.includes('.dev.foldhealth.io')) {
    defaultClassForTheme = 'all-dev-env';
  } else if (window.location.host.includes('.foldhealth.io')) {
    defaultClassForTheme = 'all-prod-env';
  }

  let appClassNames = 'App';
  if (accountClassName) {
    appClassNames = 'App ' + accountClassName + ' ' + defaultClassForTheme ;
  }

  return (
    <div className={appClassNames}>
      <I18nProvider>
        <MainScreen>
          <HashRouter>
            <Routes>
              <Route path="/">
                <Route index element={<Membership />} />
                  <Route path="checkout" element={<CheckoutForm />} />
                  <Route path="payment" element={<PaymentFormWrapper />} />
                  <Route
                    path="order-complete"
                    element={<OrderSuccessfullyPlaced />}
                  />
              </Route>
            </Routes>
          </HashRouter>
        </MainScreen>
      </I18nProvider>
    </div>
  );
}

export default App;
