import React from 'react';
import './CustomDatePicker.css';
import moment from 'moment';
import {isMonthWith31Days} from '../CheckoutForm/CheckoutUtils';

export interface CustomDatePickerProps {
  contactData: any;
  onChange: (date: any) => void;
  date?: string;
  value?: any;
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const monthRef = React.useRef<HTMLInputElement>(null);
  const dayRef = React.useRef<HTMLInputElement>(null);
  const yearRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState({
    day: '',
    month: '',
    year: '',
  });
  const reg = /^\d*[.]?\d*$/;

  const isNumber = (str: string) => {
    return reg.test(str);
  };

  const isValidMonth = (str: string) => {
    return isNumber(str) && Number(str) <= 12;
  };
  const isValidDay = (str: string) => {
    if (!isValidMonth(state.month)) {
      return false;
    }
    const month = Number(state.month);
    if (isMonthWith31Days(month)) {
      return isNumber(str) && Number(str) <= 31;
    } else {
      return isNumber(str) && Number(str) <= 30;
    }
  };

  const isTabKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    return e.key === 'Tab';
  };

  React.useEffect(() => {
    if (sessionStorage.getItem('contactAPIData') || props?.contactData) {
      let contactData: any;
      if (sessionStorage.getItem('contactAPIData')) {
        contactData = JSON.parse(
          sessionStorage.getItem('contactAPIData') || '',
        );
      } else {
        contactData = props?.contactData;
      }
      const birthDate = contactData?.person?.birthDate;
      if (birthDate) {
        const dateObj = moment(birthDate);
        var month = dateObj.month() + 1;
        var day = dateObj.date();
        var year = dateObj.year();
        setState((prev) => {
          return {
            ...prev,
            day: day.toString(),
            month: month.toString(),
            year: year.toString(),
          };
        });
        const str = `${month}/${day}/${year}`;
        props.onChange(str);
      }
    }
  }, [props?.contactData]);

  return (
    <div className="date-picker-wrapper">
      <div className="date-input-wrapper">
        <input
          type="text"
          value={state.month}
          className="date-input"
          maxLength={2}
          placeholder="MM"
          ref={monthRef}
          onBlur={(e) => {
            if (e.target.value === '0' || e.target.value === '00') {
              setState((prev) => {
                return {
                  ...prev,
                  month: '01',
                };
              });
              const str = `01/${state.day}/${state.year}`;
              props.onChange(str);
            }
          }}
          onKeyDown={(e) => {
            const value = e.currentTarget.value;
            if (!isTabKeyPressed(e)) {
              return;
            }
            if (!(value.length === 1)) {
              return;
            }
            setState((prev) => {
              return {
                ...prev,
                month: `0${prev.month}`,
              };
            });
            const str = `0${value}/${state.day}/${state.year}`;
            props.onChange(str);
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (!isValidMonth(value)) {
              return;
            }
            if (value === '00') {
              setState((prev) => {
                return {
                  ...prev,
                  month: '',
                };
              });
            } else {
              setState((prev) => {
                return {
                  ...prev,
                  month: value,
                };
              });
              const str = `${value}/${state.day}/${state.year}`;
              props.onChange(str);
              if (value.length === 2) {
                dayRef.current?.focus();
              }
            }
          }}
        />
        <div className="slash" />
        <input
          type="text"
          value={state.day}
          maxLength={2}
          className="date-input"
          placeholder="DD"
          ref={dayRef}
          onBlur={(e) => {
            if (e.target.value === '0' || e.target.value === '00') {
              setState((prev) => {
                return {
                  ...prev,
                  day: '01',
                };
              });
              const str = `${state.month}/01/${state.year}`;
              props.onChange(str);
            }
          }}
          onKeyDown={(e) => {
            const value = e.currentTarget.value;
            if (isTabKeyPressed(e)) {
              if (value.length === 1) {
                setState((prev) => {
                  return {
                    ...prev,
                    day: `0${prev.day}`,
                  };
                });
              }
            }
            if (e.currentTarget.value.length === 0 && e.key === 'Backspace') {
              monthRef.current?.focus();
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (isValidDay(value)) {
              if (value === '00') {
                setState((prev) => {
                  return {
                    ...prev,
                    day: '',
                  };
                });
              } else {
                setState((prev) => {
                  return {
                    ...prev,
                    day: value,
                  };
                });
                const str = `${state.month}/${value}/${state.year}`;
                props.onChange(str);
                if (value.length === 2) {
                  yearRef.current?.focus();
                }
              }
            }
          }}
        />
        <div className="slash" />
        <input
          maxLength={4}
          type="text"
          value={state.year}
          className="date-input year-input"
          placeholder="YYYY"
          ref={yearRef}
          onBlur={(e) => {
            const value = e.target.value;
            if (value === '0' || value === '00') {
              setState((prev) => {
                return {
                  ...prev,
                  year: '2000',
                };
              });
            }
          }}
          onKeyDown={(e) => {
            if (e.currentTarget.value.length === 0 && e.key === 'Backspace') {
              dayRef.current?.focus();
            }
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (isNumber(value)) {
              if (value === '00' || value === '0') {
                setState((prev) => {
                  return {
                    ...prev,
                    year: '',
                  };
                });
                return;
              }
              setState((prev) => {
                return {
                  ...prev,
                  year: value,
                };
              });
              const str = `${state.month}/${state.day}/${value}`;
              props.onChange(str);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CustomDatePicker;
