import {Button, Card, Col, Row, Typography} from 'antd';
import Title from 'antd/lib/typography/Title';
import {useState} from 'react';
import {useIntl} from 'react-intl';
import {COLOR_CONST} from '../../../constants/ThemeColorConst';
import {IBillingCard} from '../interface';
import {getMembershipPeriodStr} from './BillingCardUtils';

const {Text} = Typography;

const BillingCard = (props: IBillingCard) => {
  const intl = useIntl();
  const {membershipData, index, onBuyNowClick} = props;
  const [memberShipCardState, setMemberShipCardState] = useState({
    products: membershipData,
  });

  const getPriceViewByPriceCount = () => {
    return (
      <div
        style={{
          width: '100%',
          marginTop: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
            width: '100%',
          }}>
          <div
            key={index}
            style={{
              background: COLOR_CONST.cardFeatureBgColor,
              border: 1,
              borderColor: 'red',
              padding: 8,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 80,
              borderRadius: 12,
            }}>
            {memberShipCardState.products.prices.map(
              (singlePriceObj, index) => {
                if (singlePriceObj.tiers) {
                  return (
                    <>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: '1.2rem',
                          fontWeight: 700,
                          marginBottom: 0,
                          marginTop: 12,
                          justifyContent: 'flex-start',
                          padding: 0,
                          width: '100%',
                        }}>
                        {singlePriceObj.tiers[0].up_to}{' '}
                        {singlePriceObj.metadata.type}
                        {singlePriceObj.tiers.map((tier, index) => {
                          if (
                            tier.unit_amount_decimal &&
                            tier.unit_amount_decimal > 0 &&
                            !tier.up_to
                          ) {
                            return (
                              <Text
                                italic
                                type="secondary"
                                style={{fontSize: '13px'}}>
                                {' '}
                                (After That {tier.unit_amount_decimal / 100} per
                                unit)
                              </Text>
                            );
                          } else if (tier.flat_amount_decimal && !tier.up_to) {
                            return (
                              <Text
                                italic
                                type="secondary"
                                style={{fontSize: '13px'}}>
                                {' '}
                                (After That {tier.unit_amount_decimal / 100} per
                                unit)
                              </Text>
                            );
                          }
                        })}
                      </div>
                      <div
                        style={{
                          color: COLOR_CONST.cardFontColor,
                          fontSize: 12,
                          fontWeight: 400,
                          marginBottom: 0,
                          marginTop: 0,
                          justifyContent: 'flex-start',
                          textAlign: 'center',
                          padding: 0,
                        }}></div>
                    </>
                  );
                }
              },
            )}
            {/* {getMembershipPeriodStr(singlePriceObj)} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      style={{
        borderRadius: 16,
        backgroundColor: 'white',
        padding: 16,
        height: 'auto',
      }}>
      <Row style={{padding: 0}}>
        <Title
          style={{
            color: COLOR_CONST.titleColor,
            fontSize: '1.3rem',
            fontWeight: 700,
            marginBottom: 0,
            marginTop: 0,
            justifyContent: 'flex-start',
            textAlign: 'left',
            padding: 0,
            width: '90%',
          }}>
          {membershipData.name}
        </Title>
      </Row>
      <Col
        style={{
          fontSize: '1.1rem',
          padding: 25,
          paddingLeft: 8,
          paddingTop: 25,
          paddingBottom: 25,
          borderRadius: 8,
          background: COLOR_CONST.cardFeatureBgColor,
          marginTop: 16,
        }}>
        {membershipData.description}
      </Col>
      {getPriceViewByPriceCount()}
      {memberShipCardState.products.prices.map((singlePriceObj, index) => {
        if (singlePriceObj.unit_amount) {
          return (
            <>
              <div
                style={{
                  color: COLOR_CONST.cardFontColor,
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  marginBottom: 0,
                  marginTop: 20,
                  justifyContent: 'flex-start',
                  padding: 0,
                  width: '100%',
                }}>
                ${singlePriceObj.unit_amount / 100}
                {getMembershipPeriodStr(singlePriceObj)}
              </div>
            </>
          );
        }
      })}

      <Button
        type="primary"
        onClick={() => {
          onBuyNowClick({
            selectedProduct: membershipData as any,
          });
        }}
        block
        style={{
          backgroundColor: COLOR_CONST.titleColor,
          marginTop: 20,
          height: 40,
          borderRadius: 5,
          border: 'none',
        }}>
        <Text strong>{intl.formatMessage({id: 'buyNow'})}</Text>
      </Button>
    </Card>
  );
};
export default BillingCard;
function onBuyNowClick(arg0: {selectedProduct: any}) {
  throw new Error('Function not implemented.');
}
