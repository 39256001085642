import {FormInstance} from 'antd';
import moment from 'moment';
import {getPhoneNumberAndCountryCode} from '../../utils/CommonUtils';
import {IGetContact} from './../../services/Contact/ContactInterface';
import {ICheckoutForm} from './interface';

export const getCheckoutBodyData = (contactData: ICheckoutForm) => {
  const contactDetails = {
    firstname: contactData?.firstName || '',
    lastname: contactData?.lastName || '',
    // address1: contactData?.address,
    // city: contactData?.city,
    phone: contactData?.phoneNumber,
    zipcode: contactData?.zipcode,
    // state_name: contactData?.state,
    country_iso: contactData?.country_iso,
  };
  return {
    order: {
      email: contactData.email,
      bill_address_attributes: {...contactDetails},
      ship_address_attributes: {...contactDetails},
    },
  };
};

export const updateContactDataInForm = (
  form: FormInstance<ICheckoutForm>,
  contactData: IGetContact,
) => {
  let phoneNumberData = getPhoneNumberAndCountryCode(
    contactData?.phoneNumber || '',
  );

  form.setFieldValue('firstName', contactData?.person?.firstName || contactData?.firstName);
  form.setFieldValue('lastName', contactData?.person?.lastName || contactData?.lastName);
  form.setFieldValue('email', contactData?.email);
  form.setFieldValue('phoneNumber', phoneNumberData.formattedPhoneNumber || '');
  form.setFieldValue(
    'emailOrPhoneNumber',
    contactData?.email || phoneNumberData.formattedPhoneNumber || contactData?.emailOrPhoneNumber|| '',
  );

  if (contactData?.person?.gender?.code) {
    form.setFieldValue('genderCode', contactData?.person?.gender?.code);
  }

  if (contactData?.person?.sexAtBirth?.code) {
    form.setFieldValue('ehrSexAtBirthCode', contactData?.person?.sexAtBirth?.code);
  } else if (contactData?.ehrSexAtBirthCode) {
    form.setFieldValue('ehrSexAtBirthCode', contactData?.ehrSexAtBirthCode);
  }

  if (contactData?.person?.birthDate) {
    form.setFieldValue('birthDate', moment(contactData?.person?.birthDate));
  } else if (contactData?.birthDate) {
      form.setFieldValue('birthDate', moment(contactData?.birthDate));
  }

  // form.setFieldValue('address', contactData?.personAddress?.[0]?.line1);
  form.setFieldValue(
    'zipcode',
    contactData?.personAddress?.[0]?.zipcodes?.code || contactData?.zipcode || '',
  );
  // form.setFieldValue('city', contactData?.personAddress?.[0]?.cities.name);
  // form.setFieldValue('state', contactData?.personAddress?.[0]?.states.name);
  form.setFieldValue('country_iso', 'US');
};

export const isFutureDate = (dateString: string) => {
  const now = new Date();
  return new Date(dateString) > now;
};

export const isInvalidDate = (dateString: string) => {
  const isValidDate = moment(dateString, 'MM-DD-YYYY').isValid();
  return !isValidDate;
};

export const isMonthWith31Days = (month: number) => {
  const months = [1, 3, 5, 7, 8, 10, 12];
  return months.indexOf(month) !== -1;
};
