import {LOCALES} from '../locales/locales';

const SPANISH = {
  [LOCALES.SPANISH]: {
    hello: 'Holla',
    name: 'Nombre',
    phone: 'Teléfono',
    email: 'Email',
  },
};
export default SPANISH;
