import {Button, message, Modal} from 'antd';
import {useEffect, useState} from 'react';
import OtpInput from 'react-otp-input';
import {OTP_ACTION_CODE} from '../../constants/StringConst';
import {IOtpValidationFormProps} from './interface';
import {Typography} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import {
  createContactWithVerifyOTPFromAPI,
  verifyOTPFromAPI,
} from '../../services/Contact/ContactService';
import {useIntl} from 'react-intl';
import {buildCreateContactWithOtp} from './OtpUtils';
import {COLOR_CONST} from '../../constants/ThemeColorConst';
import {unmaskPhoneNumber} from '../../utils/CommonUtils';
const {Text} = Typography;
const OtpValidationForm = (props: IOtpValidationFormProps) => {
  const intl = useIntl();
  const {isModalVisible, contactData, onModalActionPerformed} = props;
  const [otpData, setOtpData] = useState({
    otp: '',
    isLoading: false,
    hasOtpInvalid: false,
  });

  const handleOptChange = (otp: any) => {
    setOtpData((prev) => {
      return {
        ...prev,
        otp: otp,
      };
    });
  };

  const onModalClose = () => {
    onModalActionPerformed(OTP_ACTION_CODE.MODAL_CLOSE);
  };

  const verifyOTP = async () => {
    if (otpData.otp.length === 6) {
      let otpVerifyContactTypeObj = {} as any;
      if (contactData?.emailOrPhoneNumber?.includes('@')) {
        contactData.email = contactData?.emailOrPhoneNumber;
      } else if (contactData?.emailOrPhoneNumber?.length === 10 && !isNaN(contactData?.emailOrPhoneNumber as any)) {
        contactData.phoneNumber = contactData?.emailOrPhoneNumber;
      }

      if (contactData.phoneNumber) {
        contactData.phoneNumber = unmaskPhoneNumber(contactData.phoneNumber);
      }

      if (contactData.email) {
        otpVerifyContactTypeObj = {
          otp: otpData.otp,
          otpVerifyContactType: 'email'
        }
      } else if(contactData?.phoneNumber) {
        otpVerifyContactTypeObj = {
          otp: otpData.otp,
          otpVerifyContactType: 'phoneNumber',
        }
      }
      const finalCreateContactData = buildCreateContactWithOtp(
        {
          ...contactData, uuid: props?.contactUuid,
          howDidYouHearAboutUs: props?.howDidYouHearAboutUs
        },
        otpVerifyContactTypeObj,
        [],
        props?.showHowDidYouHearAboutUs
      );
      const otpVerificationData: any = await createContactWithVerifyOTPFromAPI(
        finalCreateContactData,
      ).catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setOtpData((prev) => {
            return {
              ...prev,
              hasOtpInvalid: true,
            };
          });
        } else {
          message.error(
            intl.formatMessage({
              id: 'apiErrorMsg',
            }),
          );
        }
      });
      if (otpVerificationData?.status === 200) {
        onModalActionPerformed(
          OTP_ACTION_CODE.OTP_VERIFIED_SUCCESS,
          otpVerificationData,
        );
      }
      console.log(otpVerificationData);
    } else {
      setOtpData((prev) => {
        return {
          ...prev,
          hasOtpInvalid: true,
        };
      });
    }
  };

  useEffect(() => {
    const $elemList: any = document.querySelectorAll('.otp-input input');
    if ($elemList.length) {
      $elemList[0].focus();
    }
  }, [isModalVisible]);

  const getOptParaText = () => {
    const email = contactData.email;
    return (
      <Content
        style={{
          paddingBottom: 10,
        }}>
        <div>
          <Text>We have sent a verification code to your email:- </Text>
          <Text strong>{email}</Text>
        </div>
      </Content>
    );
  };

  const getClassNameForOtp = () => {
    if (otpData.hasOtpInvalid) {
      return 'otp-input error';
    }
    return 'otp-input';
  };

  return (
    <Modal
      style={{
        top: -90,
      }}
      title="Verify OTP"
      centered
      visible={isModalVisible}
      onOk={() => onModalClose()}
      onCancel={() => onModalClose()}
      footer={[
        <Button key="back" onClick={onModalClose} style={{borderRadius: 6}}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{
            backgroundColor: COLOR_CONST.bgColor1,
            borderWidth: 0,
            borderRadius: 6,
          }}
          loading={otpData.isLoading}
          onClick={verifyOTP}>
          Verify
        </Button>,
      ]}
      width={'auto'}>
      {getOptParaText()}

      <div className={getClassNameForOtp()} style={{display: 'flex'}}>
        <OtpInput
          value={otpData.otp}
          onChange={handleOptChange}
          numInputs={6}
          // separator={<span>-</span>}
        />
      </div>
    </Modal>
  );
};

export default OtpValidationForm;
