import {evnName} from '../utils/CommonUtils';

export const BASE_URL = `https://${evnName()}foldhealth.io`;
// export const BASE_URL = `http://localhost:5431`;
export const CRM_BASE_URL = BASE_URL + '/crm-nest';
export const CRM_PUBLIC_BASE_URL = CRM_BASE_URL + '/public';
const commerceBaseUrl = `https://${evnName()}foldhealth.io`;

export const SERVICES_BASE_URL = {
  COMMERCE_BASE_URL: commerceBaseUrl,
};

export const SERVICES_BASE_PATH = {
  COMMERCE_BASE_PATH: '/crm-spree/commerce/api/v2',
};

export const COMMERCE_API_PATH = {
  ORDER_LIST:
    '/storefront/account/orders?include=line_items,variants,variants.images,billing_address,shipping_address,user,payments,shipments,promotions',
  PRODUCT_LIST: '/storefront/products?include=option_types,taxons,images&',
  PRODUCT_DETAILS: '/storefront/products',
  TAXONS_LIST: '/storefront/taxons',
  OPTION_TYPE_LIST: '/platform/option_types?&',
  VARIANTS_LIST: '/platform/variants?include=digitals,option_values&',
  GET_AND_CREATE_CART:
    '/storefront/cart?include=line_items,variants,variants.images',
  ADD_ITEM_TO_CART:
    '/storefront/cart/add_item?include=line_items,variants,variants.images',
  REMOVE_ITEM_TO_CART: '/storefront/cart/remove_line_item',
  UPDATE_CHECKOUT:
    '/storefront/checkout?include=line_items,variants,variants.images',
  PROCEED_TO_PAYMENT:
    '/storefront/checkout/next?include=line_items,variants,variants.images',
  LIST_PAYMENT_METHODS: '/storefront/checkout/payment_methods',
  COMPLETE_CHECKOUT:
    '/storefront/checkout/complete?include=line_items,variants,variants.images',
  RETRIEVE_AN_ORDER_STATUS: '/storefront/order_status',
  CREATE_PAYMENT: '/storefront/checkout/create_payment',
  V1_CHECKOUT: '/crm-spree/commerce/api/v1/checkouts',
};

export const CONTACT_API_PATH = {
  GET_CONTACT: '/membership/api/contact',
  SEND_OTP: '/otp',
  VERIFY_OTP: '/membership/api/otp/verify',
  CREATE_CONTACT_WITH_VERIFY_OTP: '/contact/otp/verify',
  CREATE_CONTACT_WITHOUT_VERIFY_OTP: '/contact-without-verify',
  PATIENT_CAPABILITY: '/patient-capability',
  CUSTOM_ATTRIBUTE_ID: '/api/v1/custom-attributes/attributeId'
};
