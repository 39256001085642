import {DEFAULT_CONFIG_VALUE, EMAIL_PHONE_NUMBER_FIELD_DEAFULT_VALUE, ON_OFF_CONFIG_VALUE} from '../constants/AccountConfigConst';
import {CONFIGURATION_CODE} from '../constants/StringConst';
import {ICommonContext} from '../context/CommonContext';

export const getAccountConfigValue = (commonData: ICommonContext,code: string) => {
  const configObj = commonData?.accountConfigData?.[code];
  if (configObj?.accountConfigurations?.length) {
    return configObj?.accountConfigurations?.[0].value;
  } else if (configObj?.defaultValue) {
    return configObj?.defaultValue;
  } else if (code) {
    return DEFAULT_CONFIG_VALUE[code] || '';
  }
  return '';
};

export const getAccountConfigDefaultValue = (commonData: ICommonContext,code: string) => {
  const configObj = commonData?.accountConfigData?.[code];
  if (configObj?.defaultValue) {
    return configObj?.defaultValue;
  } else if (code === CONFIGURATION_CODE.EMAIL_PHONE_NUMBER_FIELD) {
    return EMAIL_PHONE_NUMBER_FIELD_DEAFULT_VALUE
  }
  return '';
};


export const isAccountConfigEnabled = (commonData: ICommonContext,code: string): boolean => {
  const configValue = getAccountConfigValue( commonData,code);
  if (configValue == ON_OFF_CONFIG_VALUE.ON) {
    return true;
  } else {
    return false;
  }
}
