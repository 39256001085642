import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Select,
  Spin,
} from 'antd';
import {RangePickerProps} from 'antd/lib/date-picker';
import {Content} from 'antd/lib/layout/layout';
import IMask from 'imask';
import moment from 'moment';
import {useContext, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {EMAIL_PHONE_NUMBER_CONFIG_VALUE, ON_OFF_CONFIG_VALUE} from '../../constants/AccountConfigConst';
import {PERSON_GENDER_LIST} from '../../constants/MlovConst';
import {
  CONFIGURATION_CODE,
  EMAIL_REGEX,
  GENERAL_STRING_CONST,
  HOW_DID_YOU_HEAR_ABOUT_US,
  HOW_DID_YOU_HEAR_ABOUT_US_CHAR_LIMIT,
  OTP_ACTION_CODE,
  RECURRING_PRICE_DISPLAY_CODE,
} from '../../constants/StringConst';
import {COLOR_CONST} from '../../constants/ThemeColorConst';
import {Context} from '../../context/CommonContext';
import {IGetContact} from '../../services/Contact/ContactInterface';
import {
  createContactWithOutVerifyOTPFromAPI,
  getContactInformationFromAPI,
  getCustomAttributeId,
  searchZipCode,
  sendOTPFromAPI,
} from '../../services/Contact/ContactService';
import {
  getDateToMomentISOString,
  getMembershipToken,
  getQueryParamValue,
  getSexAtBirthList,
  unmaskPhoneNumber,
} from '../../utils/CommonUtils';
import {getAccountConfigDefaultValue, getAccountConfigValue} from '../../utils/configUtils';
import {DisplayText} from '../Common/DisplayText/DisplayText';
import MaskedInput from '../Common/MaskedInput/MaskedInput';
import {CustomDatePicker} from '../CustomDatePicker';
import {IBuyNowProductData} from '../Membership/interface';
import MemberShipBackground from '../Membership/MemberShipBackground/MemberShipBackground';
import {buildCreateContactWithOtp} from '../OtpForm/OtpUtils';
import OtpValidationForm from '../OtpForm/OtpValidationForm';
import {PAYMENT_ERROR_STRING} from '../Payment/PaymentConst';
import {
  isFutureDate,
  updateContactDataInForm,
  isInvalidDate,
} from './CheckoutUtils';
import CouponCode from './CouponCode';
import {
  ICheckoutForm,
  ICheckoutFormLocationData,
  ICheckoutFormState,
} from './interface';
const {Option} = Select;
const {Option: AutoCompleteOption} = AutoComplete;
const CheckoutForm = () => {
  const commonData = useContext(Context);
  const accountData = commonData.accountData;
  const genderList = getSexAtBirthList(commonData.patientCapabilities);
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
  const datePickerRef: any = useRef(null);
  const MASKED = IMask.createMask({
    blocks: {
      DD: {from: 1, mask: IMask.MaskedRange, to: 31},
      MM: {from: 1, mask: IMask.MaskedRange, to: 12},
      YYYY: {from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE},
    },
    format: (date: Date) => moment(date).format(DISPLAY_DATE_FORMAT),
    mask: Date,
    parse: (date: string) => moment(date, DISPLAY_DATE_FORMAT),
    pattern: DISPLAY_DATE_FORMAT,
  });
  const emailOrPhoneNumberField = getAccountConfigValue(
    commonData,
    CONFIGURATION_CODE.EMAIL_PHONE_NUMBER_FIELD,
  );
  let emailOrPhoneNumberFieldParseToObject : any;
  try {
    emailOrPhoneNumberFieldParseToObject = JSON.parse(emailOrPhoneNumberField)
  } catch {
    emailOrPhoneNumberFieldParseToObject = JSON.parse(getAccountConfigDefaultValue(commonData,
      CONFIGURATION_CODE.EMAIL_PHONE_NUMBER_FIELD))
  }
  let locationData = location.state as ICheckoutFormLocationData;
  const selectedPriceType =
    locationData?.selectedProduct?.selectedPrice?.type || '';
  if (!locationData?.selectedProduct && !locationData?.selectedProducts) {
    navigate('/');
  }
  const showHowDidYouHearAboutUs = getAccountConfigValue(
    commonData,
    CONFIGURATION_CODE.CUSTOM_FIELD_HOW_DID_YOU_HEAR_ABOUT_US,
  );

  if (!locationData) {
    locationData = {} as any;
  }

  if (locationData.selectedProduct?.selectedPrice?.id) {
    sessionStorage.setItem(
      'selectedProduct',
      JSON.stringify(locationData.selectedProduct),
    );
  }

  if (locationData.selectedProducts) {
    sessionStorage.setItem(
      'selectedProducts',
      JSON.stringify(locationData.selectedProducts),
    );
  }

  const [form] = Form.useForm<ICheckoutForm>();
  const foldCustAccount = getQueryParamValue('foldCustAccountUuid');
  const foldCustAccountUuid = foldCustAccount
    ? foldCustAccount
    : accountData?.accountToSubdomain?.uuid;
  const [checkoutFormState, setCheckoutFormState] =
    useState<ICheckoutFormState>({
      isLoading: true,
      isLoadingZipCode: false,
      contactData: {} as IGetContact,
      showOtpModal: false,
      zipcodeList: [],
      couponCode: '',
      birthDate: '',
      selectedPrice: {},
      showErrorModal: false,
      errorMessage: '',
      isPricePromotionCodeEnabled: false,
      howDidYouHearAboutUs: {
        text: '',
        id: ''
      },
      howDidYouHearAboutUsText: 'howDidYouHearAboutUs'
    });
  const [error, setError] = useState({dateError: ''});
  const onFinish = async (contactData: ICheckoutForm) => {
    if (showLimitError()) {
      return;
    }
    let isValidateOtp = false;
    let contactOtpData = {} as any;
    // contactData.birthDate = contactData.birthDate
    contactData.country_iso = 'US';
    if (isEmailOrPhoneNumberField()) {
      if (contactData?.emailOrPhoneNumber.includes('@')) {
        contactData.email = contactData?.emailOrPhoneNumber;
      } else if (
        contactData?.emailOrPhoneNumber?.length === 10 &&
        !isNaN(contactData?.emailOrPhoneNumber as any)
      ) {
        contactData.phoneNumber = contactData?.emailOrPhoneNumber;
      }
    }
    if (contactData.phoneNumber) {
      contactData.phoneNumber = unmaskPhoneNumber(contactData.phoneNumber);
    }
    if (accountData?.additionalAttributes?.MEMBERSHIP_OTP_VALIDATE == true) {
      isValidateOtp = true;
    }
    if (isValidateOtp) {
      if (contactData.email) {
        contactOtpData = {
          contact: contactData.email,
          contactType: 'email',
        };
      } else if (contactData?.phoneNumber) {
        contactOtpData = {
          contact: '+1' + contactData?.phoneNumber,
          contactType: 'phoneNumber',
        };
      }

      const sendOtpData = await sendOTPFromAPI(contactOtpData).catch(() => {
        message.error(
          intl.formatMessage({
            id: 'errorSendingOtp',
          }),
        );
      });

      if (sendOtpData) {
        message.success(
          intl.formatMessage({
            id: 'successSendingOtp',
          }),
        );
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            showOtpModal: true,
          };
        });
      }
    } else {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoading: true,
        };
      });
      // contact-without-verify
      await createContactWithoutValidateOtp();
    }
  };

  const createContactWithoutValidateOtp = async () => {
    const contactData = form.getFieldsValue();
    if (typeof contactData.birthDate === 'object') {
      contactData.birthDate = moment(contactData.birthDate).format(
        'MM-DD-YYYY',
      );
    }
    // contactData.birthDate = getDateToMomentISOString(contactData.birthDate);
    const finalCreateContactData = buildCreateContactWithOtp(
      {
        ...contactData,
        uuid: checkoutFormState?.contactData?.uuid,
        howDidYouHearAboutUs: checkoutFormState?.howDidYouHearAboutUs
      },
      undefined,
      undefined,
      showHowDidYouHearAboutUs === ON_OFF_CONFIG_VALUE.ON ? true : false
    );
    const contactApiData: any = await createContactWithOutVerifyOTPFromAPI(
      finalCreateContactData,
    ).catch((error) => {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
      if (error.response?.data?.code === GENERAL_STRING_CONST.ACTIVE_MEMBERSHIP) {
        message.error(error.response?.data?.message)
        return
      }
      const errorMessage = error.response?.data?.message || error.response?.data?.code
      if (errorMessage) {
        message.error(errorMessage)
        return;
      }
      message.error(
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
      );
    });

    if (contactApiData?.data?.contact?.uuid) {
      contactData.uuid = contactApiData?.data?.contact?.uuid;
    }

    if (contactApiData?.data?.contact?.id) {
      contactData.id = contactApiData?.data?.contact?.id;
    }

    if (contactApiData?.data?.uuid) {
      contactData.uuid = contactApiData?.data?.uuid;
    }

    if (contactApiData?.data?.id) {
      contactData.id = contactApiData?.data?.id;
    }
    setCheckoutFormState((prev) => {
      return {
        ...prev,
        isLoading: false,
      };
    });
    if (contactApiData?.data?.contact?.id) {
      sessionStorage.setItem(
        'contactAPIData',
        JSON.stringify(contactApiData?.data?.contact),
      );
    }

    if (locationData.selectedProduct) {
      sessionStorage.setItem(
        'selectedProduct',
        JSON.stringify(locationData.selectedProduct),
      );
    }

    if (locationData.selectedProducts) {
      sessionStorage.setItem(
        'selectedProducts',
        JSON.stringify(locationData.selectedProducts),
      );
    }
    if (contactApiData?.data?.token) {
      sessionStorage.setItem('token', contactApiData?.data?.token);
    }
    navigate('/payment', {
      state: {
        contactFormData: contactData,
        foldCustAccountUuid: foldCustAccountUuid,
        contactData: contactApiData?.data?.contact,
        selectedProduct: locationData.selectedProduct,
        selectedProducts: locationData.selectedProducts,
        couponCode: checkoutFormState.couponCode,
        selectedPriceType: selectedPriceType,
        token: contactApiData.data.token,
      },
    });
  };

  const onZipcodeSearch = async (searchString: string) => {
    setCheckoutFormState((prev) => {
      return {
        ...prev,
        isLoadingZipCode: true,
        zipcodeList: [],
      };
    });
    const zipCodeResp = await searchZipCode(`%${searchString}%`);
    if (zipCodeResp.data?.data?.zipcodes?.length) {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoadingZipCode: false,
          zipcodeList: [...zipCodeResp.data?.data?.zipcodes],
        };
      });
    } else {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoadingZipCode: false,
          zipcodeList: [],
        };
      });
    }
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    // Can not select days before today and today
    return current && current > moment().endOf('day');
  };
  const onOtpModalActionPerformed = (actionCode: string, actionData: any) => {
    switch (actionCode) {
      case OTP_ACTION_CODE.MODAL_CLOSE:
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            showOtpModal: false,
          };
        });
        break;
      case OTP_ACTION_CODE.OTP_VERIFIED_SUCCESS:
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            showOtpModal: false,
          };
        });
        let contactData = form.getFieldsValue();
        if (typeof contactData.birthDate === 'object') {
          contactData.birthDate = moment(contactData.birthDate).format(
            'MM-DD-YYYY',
          );
        }
        // contactData.birthDate = getDateToMomentISOString(contactData.birthDate);

        if (actionData?.data?.contact?.uuid) {
          contactData = {...contactData, ...actionData.data.contact};
          contactData.uuid = actionData?.data?.contact?.uuid;
        }

        if (actionData?.data?.contact?.id) {
          contactData.id = actionData?.data?.contact?.id;
        }

        if (actionData?.data?.uuid) {
          contactData.uuid = actionData?.data?.uuid;
        }

        if (actionData?.data?.id) {
          contactData.id = actionData?.data?.id;
        }
        if (contactData?.id) {
          sessionStorage.setItem('contactAPIData', JSON.stringify(contactData));
        }

        if (locationData.selectedProduct) {
          sessionStorage.setItem(
            'selectedProduct',
            JSON.stringify(locationData.selectedProduct),
          );
        }

        if (locationData.selectedProducts) {
          sessionStorage.setItem(
            'selectedProducts',
            JSON.stringify(locationData.selectedProducts),
          );
        }
        navigate('/payment', {
          state: {
            contactFormData: contactData,
            foldCustAccountUuid: foldCustAccountUuid,
            contactData: checkoutFormState.contactData,
            selectedProduct: locationData.selectedProduct,
            selectedProducts: locationData.selectedProducts,
            couponCode: checkoutFormState.couponCode,
            selectedPriceType: selectedPriceType
          },
        });
        break;

      default:
        break;
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const getContactInformation = async (customAttributeId: string) => {
    const membershipAccessToken = getMembershipToken() || '';
    const contactAPIDataJsonStr = sessionStorage.getItem('contactAPIData');
    const selectedProductJsonStr = sessionStorage.getItem('selectedProduct');
    const selectedProductsJsonStr = sessionStorage.getItem('selectedProducts');

    // alert(selectedProductJsonStr);
    let contactAPIData = {} as IGetContact;
    let selectedProductData = {} as IBuyNowProductData;
    let selectedProductsData = {} as IBuyNowProductData;
    if (contactAPIDataJsonStr) {
      try {
        contactAPIData = JSON.parse(contactAPIDataJsonStr);
      } catch (error) {
        contactAPIData = {} as IGetContact;
      }
    }

    if (selectedProductJsonStr) {
      try {
        selectedProductData = JSON.parse(selectedProductJsonStr);
        if (selectedProductData?.selectedProduct?.id) {
          locationData.selectedProduct = selectedProductData;
        }
      } catch (error) {
        selectedProductData = {} as IBuyNowProductData;
        locationData.selectedProduct = selectedProductData;
      }
    }

    if (selectedProductsJsonStr) {
      try {
        selectedProductsData = JSON.parse(selectedProductsJsonStr);
        if (selectedProductsData?.selectedProduct?.id) {
          locationData.selectedProducts = selectedProductsData;
        }
      } catch (error) {
        selectedProductsData = {} as IBuyNowProductData;
        locationData.selectedProducts = selectedProductsData;
      }
    }
    if (membershipAccessToken) {
      const contactInfoData = await getContactInformationFromAPI({
        customAttributeId
      }).catch(
        (error) => {
          console.log(error);
          if (error?.response?.status === 401) {
            message.error(
              intl.formatMessage({
                id: 'tokenExpired',
              }),
            );
          } else {
            message.error(
              intl.formatMessage({
                id: 'apiErrorMsg',
              }),
            );
          }
        },
      );
      if (contactInfoData?.data?.data?.contacts?.length) {
        const attributeValue = contactInfoData?.data?.data?.contacts[0]?.customAttributeValues?.[0]?.value;
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            howDidYouHearAboutUs: {
              ...prev.howDidYouHearAboutUs,
              text: attributeValue ? attributeValue : '',
            }
          }
        })
        updateContactDataInForm(form, contactInfoData?.data?.data.contacts[0]);
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            isLoading: false,
            contactData: contactInfoData?.data?.data.contacts[0],
          };
        });
      }
    } else if (contactAPIData?.id) {
      updateContactDataInForm(form, contactAPIData);
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoading: false,
          contactData: contactAPIData,
        };
      });
    } else {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isLoading: false,
        };
      });
    }
  };

  const onChange = (value: any) => {
    setCheckoutFormState((prev) => {
      return {
        ...prev,
        birthDate: value,
      };
    });
  };

  const checkIfRedirectToPayment = () => {
    if (locationData?.redirectToPayment) {
      navigate('/payment', {
        state: {
          contactFormData: checkoutFormState?.contactData,
          foldCustAccountUuid: foldCustAccountUuid,
          contactData: checkoutFormState.contactData,
          selectedProduct: locationData.selectedProduct,
          selectedProducts: locationData.selectedProducts,
          couponCode: checkoutFormState.couponCode,
          selectedPriceType: selectedPriceType
        },
      });
    }
  }

  if (checkoutFormState?.contactData) {
    if(checkoutFormState?.contactData?.person?.sexAtBirth) {
      checkIfRedirectToPayment()
    }
  }

  const getErrorMessage = () => {
    if (checkoutFormState?.errorMessage === PAYMENT_ERROR_STRING.COUPON_APPLIES_TO_NOTHING) {
      return 'Error while applying coupon';
    } else if (checkoutFormState?.errorMessage === PAYMENT_ERROR_STRING.TRIAL_ALREADY_TAKEN) {
      return 'Trial already taken'
    }
  }

  const getAttributeId = async () => {
    if (showHowDidYouHearAboutUs === ON_OFF_CONFIG_VALUE.ON) {
      try {
        const customAttributeId = await getCustomAttributeId({
          code: HOW_DID_YOU_HEAR_ABOUT_US
        })
        setCheckoutFormState((prev) => {
          return {
            ...prev,
            howDidYouHearAboutUs: {
              ...prev.howDidYouHearAboutUs,
              id: customAttributeId?.data?.id,
            }
          }
        })
        if (customAttributeId?.data?.id) {
          return customAttributeId?.data;
        } else {
          message.error(
            intl.formatMessage({
              id: 'errorAttributeNotFound',
            }),
          );
          return;
        }
      } catch (err) {
          message.error(
            intl.formatMessage({
              id: 'errorGettingAttributeId',
            }),
          );
      }
    }
    return;
  }

  const showLimitError = () => {
    if (checkoutFormState?.howDidYouHearAboutUs?.text) {
      if (checkoutFormState?.howDidYouHearAboutUs?.text?.length > HOW_DID_YOU_HEAR_ABOUT_US_CHAR_LIMIT) {
        return true;
      }
    }
    return false;
  }

  const getCustomAttributeIdAndContactInfo = async () => {
    const customAttributeData = await getAttributeId();
    if (customAttributeData?.id) {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          howDidYouHearAboutUsText: customAttributeData?.label || 'howDidYouHearAboutUs'
        }
      })
    }
    getContactInformation(customAttributeData?.id);
  }

  useEffect(() => {
    getCustomAttributeIdAndContactInfo();
    if (accountData?.additionalAttributes?.IS_PRICE_PROMOTION_CODE_ENABLED === true) {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          isPricePromotionCodeEnabled: true
        }
      })
    }
  }, []);

  useEffect(() => {
    if (locationData?.showErrorModal) {
      setCheckoutFormState((prev) => {
        return {
          ...prev,
          showErrorModal: true,
          errorMessage: locationData?.paymentError || ''
        }
      })
    }
  }, []);

  const unmaskPhoneNumberAndCheckValid = (value:string) => {
    return unmaskPhoneNumber(value).length === 10 ? true : false;
  };

  const isEmailOrPhoneNumberField = () => {
    if (Object.keys(emailOrPhoneNumberFieldParseToObject)[0] === EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL_OR_PHONE){
      return true;
    }
    return false;
  }

  const showField = (field:string) => {
    const filedArray = Object.keys(emailOrPhoneNumberFieldParseToObject)
    if (filedArray.includes(field)){
      return true;
    }
    return false;
  }

  const isFieldRequired = (field:string) => {
    if (emailOrPhoneNumberFieldParseToObject[field].isRequired) {
      return true;
    }
    return false
  }

  return (
    <MemberShipBackground>
      <div className="fh-checkout-form-spin">
        <Spin
          size="large"
          spinning={checkoutFormState.isLoading}
          style={{
            top: '26%',
            position: 'fixed',
          }}>
          <div className="fh-checkout-form" style={{}}>
            {checkoutFormState.isLoading === false && (
              <Content
                style={{
                  padding: 16,
                }}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  style={{justifyContent: 'center', alignItems: 'center'}}>
                  <div className="fh-checkout-form-item-wrapper">
                    <Form.Item
                      label={<DisplayText textLocalId="firstName" />}
                      name="firstName"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the first name',
                        },
                      ]}>
                      <Input autoComplete="off" placeholder="John" />
                    </Form.Item>

                    <Form.Item
                      label={<DisplayText textLocalId="lastName" />}
                      name="lastName"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please enter the first name',
                        },
                      ]}>
                      <Input autoComplete="off" placeholder="Doe" />
                    </Form.Item>

                    <Form.Item
                      label={<DisplayText textLocalId="sexAtBirth" />}
                      name="ehrSexAtBirthCode"
                      required
                      rules={[
                        {
                          required: true,
                          message: 'Please select the gender',
                        },
                      ]}>
                      <Select
                        placeholder="Select a gender"
                        style={{display: 'flex', alignContent: 'flex-start'}}>
                        {PERSON_GENDER_LIST.map((singleGender) => {
                          return (
                            <Option
                              key={singleGender.code}
                              style={{
                                display: 'flex',
                                alignContent: 'flex-start',
                              }}
                              value={singleGender.code}>
                              {singleGender.value}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={<DisplayText textLocalId="dob" />}
                      name="birthDate"
                      required
                      rules={[
                        ({getFieldValue}) => ({
                          validator(_, value: any) {
                            if (typeof value === 'object') {
                              value = value.format('MM-DD-YYYY');
                            }
                            if (!value) {
                              return Promise.reject(
                                new Error('Date is required'),
                              );
                            }
                            if (isInvalidDate(value)) {
                              return Promise.reject(
                                new Error('Please enter valid date'),
                              );
                            }
                            let arr;
                            if (value.includes('-')) {
                              arr = value.split('-');
                            } else {
                              arr = value.split('/');
                            }
                            if (
                              !arr[0].length ||
                              !arr[1].length ||
                              arr[2].length < 4
                            ) {
                              return Promise.reject('Please add proper date');
                            } else if (arr[0] === '0') {
                              return Promise.reject(
                                new Error('Please enter proper month value'),
                              );
                            } else if (arr[1] === '0') {
                              return Promise.reject(
                                new Error('Please enter proper date value'),
                              );
                            } else if (
                              isFutureDate(value) ||
                              value === 'Invalid Date' ||
                              value.toString() === 'Invalid date'
                            ) {
                              return Promise.reject(
                                new Error('Please enter valid date'),
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}>
                      <CustomDatePicker
                        contactData={checkoutFormState?.contactData}
                        onChange={(date) => {
                          onChange(date);
                        }}
                      />
                    </Form.Item>
                    {
                      isEmailOrPhoneNumberField() === false ? (
                        <>
                          {showField(EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL) && <Form.Item
                            label={<DisplayText textLocalId="email" />}
                            name="email"
                            required = {isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL)}
                            rules={[
                              ({getFieldValue}) => ({
                                validator(_, value: string) {
                                  if (!value) {
                                    if (!isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        'Please enter email address',
                                      ),
                                    );
                                  } else {
                                    if (value.includes('@')) {
                                      if (!value.match(EMAIL_REGEX)) {
                                        return Promise.reject(
                                          new Error(
                                            'Please enter valid email',
                                          ),
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    } else if (
                                      value.length === 10 &&
                                      !isNaN(value as any)
                                    ) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        new Error(
                                          'Please enter valid email',
                                        ),
                                      );
                                    }
                                  }
                                },
                              }),
                            ]}>
                            <Input
                              autoComplete="off"
                              placeholder="Please enter email"
                            />
                          </Form.Item>}
                          {showField(EMAIL_PHONE_NUMBER_CONFIG_VALUE.PHONE_NUMBER) && <Form.Item
                            label={<DisplayText textLocalId="phoneNumber" />}
                            name="phoneNumber"
                            required = {isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.PHONE_NUMBER)}
                            rules={[
                              ({getFieldValue}) => ({
                                validator(_, value: string) {
                                  if (!value) {
                                    if (!isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.PHONE_NUMBER)) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      new Error(
                                        'Please enter phone number',
                                      ),
                                    );
                                  } else {
                                    if (unmaskPhoneNumberAndCheckValid(value)) {
                                      return Promise.resolve();
                                    } else {
                                      if (unmaskPhoneNumber(value).length) {
                                        return Promise.reject(
                                          new Error(
                                            'Please enter valid phone number',
                                          ),
                                        );
                                      }
                                    }
                                  }
                                },
                              }),
                            ]}
                          >
                            <MaskedInput
                              autoComplete="off"
                              mask="(999) 999-9999"
                              placeholder="Enter phone number"
                            />
                          </Form.Item>}
                        </>
                      ) : (<Form.Item
                        label={<DisplayText textLocalId="emailOrPhoneNumber" />}
                        name="emailOrPhoneNumber"
                        required = {isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL_OR_PHONE)}
                        rules={[
                          ({getFieldValue}) => ({
                            validator(_, value: string) {
                              if (!value) {
                                if (!isFieldRequired(EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL_OR_PHONE)) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    'Please enter email address or phone number',
                                  ),
                                );
                              } else {
                                if (value.includes('@')) {
                                  if (!value.match(EMAIL_REGEX)) {
                                    return Promise.reject(
                                      new Error(
                                        'Please enter valid email or phone number number',
                                      ),
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                } else if (
                                  value.length === 10 &&
                                  !isNaN(value as any)
                                ) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(
                                    new Error(
                                      'Please enter valid email or phone number number',
                                    ),
                                  );
                                }
                              }
                            },
                          }),
                        ]}>
                        <Input
                          autoComplete="off"
                          placeholder="Please enter email or phone number"
                        />
                      </Form.Item>)
                    }

                    {/* <Form.Item
                    label={<DisplayText textLocalId="phoneNumber" />}
                    name="phoneNumber"
                    rules={[]}>
                    <MaskedInput
                      autoComplete="off"
                      mask="(999) 999-9999"
                      placeholder="(___) ___-____"
                    />
                  </Form.Item> */}
                    {/* <Form.Item
                    label={<DisplayText textLocalId="address" />}
                    name="address"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please enter address',
                      },
                    ]}>
                    <TextArea
                      autoComplete="off"
                      placeholder="11, Main Street"
                    />
                  </Form.Item> */}
                    <Form.Item
                      label={<DisplayText textLocalId="zipcode" />}
                      name="zipcode"
                      rules={[
                        {
                          pattern: /\d+$/g,
                          message: 'Please enter the valid zipcode',
                        },
                      ]}>
                      <Input autoComplete="off" placeholder="10001" />
                    </Form.Item>
                    {showHowDidYouHearAboutUs === ON_OFF_CONFIG_VALUE.ON && <Form.Item
                      label={<DisplayText textLocalId={checkoutFormState?.howDidYouHearAboutUsText} />}
                      validateStatus={showLimitError() ? 'error' : ''}
                      help={showLimitError() ? `Maximum ${HOW_DID_YOU_HEAR_ABOUT_US_CHAR_LIMIT} characters are allowed` : ''}
                    >
                      <Input
                        value={checkoutFormState?.howDidYouHearAboutUs?.text}
                        disabled={checkoutFormState?.contactData?.uuid ? true : false}
                        autoComplete="off"
                        placeholder="e.g., Google, Referral, Ad"
                        onChange={(event)=> {
                          setCheckoutFormState((prev) => {
                            return {
                              ...prev,
                              howDidYouHearAboutUs: {
                                ...prev.howDidYouHearAboutUs,
                                text: event?.target?.value
                              },
                            }
                          })
                        }}
                      />
                    </Form.Item>}
                    <Form.Item
                    ></Form.Item>
                    {/* <Form.Item
                    label={<DisplayText textLocalId="zipcode" />}
                    name="zipcode"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please enter zipcode',
                      },
                    ]}>
                    <AutoComplete
                      onSearch={(value) => {
                        onZipcodeSearch(value);
                      }}
                      onSelect={(
                        value: any,
                        options: {
                          key: string;
                          value: string;
                          data: IZipCode;
                        },
                      ) => {
                        console.log(value, options);
                        if (options?.data?.id) {
                          if (options?.data?.zipcodeCity?.name) {
                            form.setFieldValue(
                              'city',
                              options?.data?.zipcodeCity?.name,
                            );
                          }
                          if (options?.data?.zipcodeState?.name) {
                            form.setFieldValue(
                              'state',
                              options?.data?.zipcodeCity?.name,
                            );
                          }
                        }
                      }}
                      placeholder="Search Zipcode"
                      notFoundContent={
                        checkoutFormState.isLoadingZipCode && (
                          <Spin size="small" />
                        )
                      }>
                      {checkoutFormState?.zipcodeList?.map((singleZipCode) => {
                        return (
                          <AutoCompleteOption
                            key={singleZipCode.id}
                            value={singleZipCode.code}
                            data={singleZipCode}>
                            {singleZipCode.code}
                          </AutoCompleteOption>
                        );
                      })}
                    </AutoComplete>
                  </Form.Item> */}
                    {/* <Form.Item
                    label={<DisplayText textLocalId="city" />}
                    name="city"
                    required
                    rules={[
                      {
                        required: true,
                        message: 'Please enter zipcode',
                      },
                    ]}>
                    <Input autoComplete="off" placeholder="New York" />
                  </Form.Item> */}
                  </div>
                  <div className='fh-checkout-form-item-wrapper' style={{alignItems: 'center'}}>
                    {selectedPriceType !==
                      RECURRING_PRICE_DISPLAY_CODE.ONE_TIME &&
                      checkoutFormState?.isPricePromotionCodeEnabled ? (
                      <Form.Item
                        style={{
                          paddingTop:
                            checkoutFormState?.isPricePromotionCodeEnabled
                              ? 16
                              : 0,
                        }}>
                        <Popover
                          title={<DisplayText textLocalId="applyCouponCode" />}
                          trigger="click"
                          content={
                            <CouponCode
                              onApplyCouponCode={(couponCode: string) => {
                                setCheckoutFormState((prev) => {
                                  return {
                                    ...prev,
                                    couponCode: couponCode,
                                  };
                                });
                              }}
                            />
                          }>
                          {selectedPriceType !==
                            RECURRING_PRICE_DISPLAY_CODE.ONE_TIME &&
                            checkoutFormState?.isPricePromotionCodeEnabled && (
                              <Button
                                style={{
                                  color: COLOR_CONST.cardTitleColor,
                                  fontSize: '22px',
                                  fontWeight: '500',
                                  letterSpacing: '2px',
                                }}
                                type="link">
                                Have coupon code?
                              </Button>
                            )}
                        </Popover>
                      </Form.Item>
                    ) : (
                      <></>
                    )}

                    <Form.Item
                      style={{
                        paddingTop: 16,
                      }}>
                      <Button
                        shape={'round'}
                        className="fh-membership-checkout"
                        type="primary"
                        htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Content>
            )}
          </div>

          {checkoutFormState.showOtpModal && (
            <OtpValidationForm
              contactData={form.getFieldsValue()}
              contactUuid={checkoutFormState?.contactData?.uuid}
              isModalVisible={checkoutFormState.showOtpModal}
              onModalActionPerformed={onOtpModalActionPerformed}
              showHowDidYouHearAboutUs={ showHowDidYouHearAboutUs === ON_OFF_CONFIG_VALUE.ON ? true : false}
              howDidYouHearAboutUs={checkoutFormState?.howDidYouHearAboutUs}
            />
          )}
        </Spin>
      </div>
      <Modal
        title={'Message'}
        width={365}
        visible={checkoutFormState?.showErrorModal}
        onCancel={() => {
          setCheckoutFormState((prev) => {
            return {
              ...prev,
              showErrorModal: false,
            };
          });
        }}
        footer={null}>
        <div style={{marginBottom: '6px'}}>{getErrorMessage()}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            padding: '10px',
          }}></div>
      </Modal>
    </MemberShipBackground>
  );
};

export default CheckoutForm;
