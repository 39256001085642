import axios, {AxiosInstance} from 'axios';
import {IAccountSubdomainMap} from '../../components/MainPage/IAccountSubDomain';
import {
  SERVICES_BASE_URL,
  SERVICES_BASE_PATH,
} from '../../constants/UrlConfigs';
import {
  getCommerceSubdomain,
} from '../../utils/CommonUtils';

export default class CommerceCommonService {
  private static commonServiceInstance: CommerceCommonService;
  public axiosService: AxiosInstance;
  private accountData: IAccountSubdomainMap = {} as any;
  constructor(tempAccountData?: IAccountSubdomainMap) {
    if (CommerceCommonService.commonServiceInstance) {
      throw new Error(
        'Use CommerceCommonService.commonServiceInstance instead of new.',
      );
    }
    const headers: any = {
      'Content-type': 'application/json',
    };
    if (tempAccountData) {
      this.accountData = tempAccountData;
      if (this?.accountData?.subDomain) {
        headers.subdomain = getCommerceSubdomain(this.accountData.subDomain);
      }
    }

    this.axiosService = axios.create({
      baseURL: `${SERVICES_BASE_URL.COMMERCE_BASE_URL}${SERVICES_BASE_PATH.COMMERCE_BASE_PATH}`,
      headers: {
        ...headers,
      },
    });

    this.axiosService.interceptors.request.use(
      async (config) => {
        // const membershipAccessToken = getMembershipToken() || '';
        if (this?.accountData?.subDomain) {
          headers.subdomain = getCommerceSubdomain(this.accountData.subDomain);
        }
        config.headers = {
          ...config.headers,
          ...headers,
        };
        return config;
      },
      (error) => {
        console.log(error);
      },
    );

    CommerceCommonService.commonServiceInstance = this;
  }

  static getCommerceCommonServiceInstance(
    accountData?: IAccountSubdomainMap,
  ): CommerceCommonService {
    if (CommerceCommonService.commonServiceInstance) {
      CommerceCommonService.commonServiceInstance.accountData =
        accountData as any;
    }
    return (
      CommerceCommonService.commonServiceInstance ??
      (CommerceCommonService.commonServiceInstance = new CommerceCommonService(
        accountData,
      ))
    );
  }
}
