import {
  RECURRING_PRICE_CODE,
  RECURRING_PRICE_DISPLAY_STR,
} from '../../../constants/StringConst';
import {IStripeProductPrice} from '../../../services/StripeProductServices/IProduct';

export const getMonthString = (intervalCount: number) => {
  if (intervalCount === 3) {
    return RECURRING_PRICE_DISPLAY_STR.QUARTERLY;
  } else if (intervalCount === 1) {
    return RECURRING_PRICE_DISPLAY_STR.MONTHLY;
  } else {
    return RECURRING_PRICE_DISPLAY_STR.MONTHLY;
  }
};

export const getMembershipPeriodStr = (singlePriceObj: IStripeProductPrice) => {
  let displayString = 'One Time';
  switch (singlePriceObj.recurring?.interval) {
    case RECURRING_PRICE_CODE.DAY:
      displayString = RECURRING_PRICE_DISPLAY_STR.DAILY;
      break;
    case RECURRING_PRICE_CODE.WEEK:
      displayString = RECURRING_PRICE_DISPLAY_STR.WEEKLY;
      break;
    case RECURRING_PRICE_CODE.MONTH:
      displayString = getMonthString(singlePriceObj.recurring.interval_count);
      break;
    case RECURRING_PRICE_CODE.YEAR:
      displayString = RECURRING_PRICE_DISPLAY_STR.YEARLY;
      break;
  }

  return displayString;
};
