export const EMAIL_PHONE_NUMBER_CONFIG_VALUE = {
  EMAIL_OR_PHONE: 'EMAIL_OR_PHONE',
  EMAIL_AND_PHONE: 'EMAIL_AND_PHONE',
  EMAIL: 'EMAIL',
  PHONE_NUMBER: 'PHONE_NUMBER',
}
export const ON_OFF_CONFIG_VALUE = {
  ON: 'ON',
  OFF: 'OFF',
}

export const EMAIL_PHONE_NUMBER_FIELD_DEAFULT_VALUE = '{"EMAIL_OR_PHONE": {"isRequired": true}}'

export const DEFAULT_CONFIG_VALUE: {[index: string]: string | any} = {
  EMAIL_PHONE_NUMBER_FIELD: EMAIL_PHONE_NUMBER_CONFIG_VALUE.EMAIL_OR_PHONE,
  HIDE_PATIENT_APP_LINK: ON_OFF_CONFIG_VALUE.OFF,
  ORDER_COMPLETE_TITLE_SUBTITLE_TEXT: '{"showSubtitleForOneTime":"OFF","title":"Membership successfully purchased","subTitle":"#patientLink#"}',
};
