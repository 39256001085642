import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Modal, Result, Spin, message} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {RECURRING_PRICE_DISPLAY_CODE} from '../../constants/StringConst';
import {Context} from '../../context/CommonContext';
import {IPaymentIntentResp} from '../../services/ProductAndServices/interfaces/StripePayment';
import MemberShipBackground from '../Membership/MemberShipBackground/MemberShipBackground';
import {
  IPaymentFormWrapperState,
  IPaymentLocationStateProps,
} from './interface';
import {ISubscriptionResp} from './ISubscription';
import {PAYMENT_ERROR_STRING} from './PaymentConst';
import PaymentForm from './PaymentForm';
import {
  initializeProductPurchase,
  paySubscription,
  setupIntent,
} from './PaymentService';
import {PAYMENT_DARK_BLUE_THEME} from './PaymentThemeConst';
import {getCreateCustomerDetails} from './PaymentUtils';
import {useIntl} from 'react-intl';

const PaymentFormWrapper = () => {
  //Remove PriceId
  const commonData = useContext(Context);
  const accountData = commonData.accountData;
  const stripeApiKeyData = commonData.stripeApiKeyData;
  const location = useLocation();
  const navigate = useNavigate();
  const locationData = location.state as IPaymentLocationStateProps;
  const intl = useIntl();

  const {
    contactAPIData,
    contactFormData,
    selectedProduct,
    selectedProducts,
    foldCustAccountUuid,
    couponCode,
    token,
  } = locationData;
  const [paymentForm, setPaymentForm] = useState<IPaymentFormWrapperState>({
    isLoading: true,
    paymentIntentData: {} as IPaymentIntentResp,
    subscriptionData: {} as ISubscriptionResp,
    customerDetails: {} as any,
    setupIntent: {} as any,
    paymentIntent: {} as any,
  });
  const trialPeriodDays= selectedProduct?.selectedPrice?.recurring?.trial_period_days || ''
  const stripePromise = loadStripe(stripeApiKeyData.apiKey);
  const selectedPriceType = selectedProduct?.selectedPrice?.type || '';
  const planAmount = (selectedProduct?.selectedPrice?.unit_amount || -1) / 100;

  const createSubscription = async () => {
    const createCustomerDetails = getCreateCustomerDetails(contactFormData);
    const priceId = selectedProduct?.selectedPrice?.id;
    let subscriptionPlanList = [];
    if (priceId) {
      const price = {
        plan: priceId,
      };
      subscriptionPlanList.push(price);
    } else if (selectedProducts) {
      for (const price of selectedProducts.selectedProduct.prices) {
        const basePrice = {
          plan: price.id,
        };
        subscriptionPlanList.push(basePrice);
      }
    }

    const storeItems = {
      id: selectedProduct?.selectedPrice?.id,
      priceInCents: selectedProduct?.selectedPrice?.unit_amount_decimal,
      name: selectedProduct?.selectedProduct?.name,
      description: selectedProduct?.selectedProduct?.description,
      quantity: 1,
      email:contactFormData?.email,
      metaData: selectedProduct?.selectedProduct?.metadata
    };
    const currentLocation = window?.location
    const currentLocationToString = currentLocation.toString()
    const redirectLink=currentLocationToString.replace("payment", "order-complete")
    const redirectBack=currentLocationToString.replace("payment", "")
    let showErrorModal = false;
    let paymentError = '';
    let subscriptionData = {} as any;
    sessionStorage.setItem('subscriptionData', JSON.stringify(subscriptionData));
    // if type is one time, then need to call the initialize-Product-purchase
    subscriptionData = await paySubscription({
      accountId: accountData.accountId,
      accountUuid: accountData?.accountToSubdomain?.uuid,
      contactId: contactFormData?.id || 0,
      contactUuid: contactFormData?.uuid || '',
      customerDetails: createCustomerDetails,
      foldCustAccountUuid: foldCustAccountUuid,
      subscriptionPlanList,
      couponCode: couponCode || '',
      trialPeriodDays:trialPeriodDays,
      redirectLink:redirectLink,
      redirectBack:redirectBack,
      storeItems: storeItems,
      oneTime: selectedPriceType !== RECURRING_PRICE_DISPLAY_CODE.ONE_TIME ?  false : true
    })
    .catch((error)=>{
      if(error?.response?.data?.code === PAYMENT_ERROR_STRING.COUPON_APPLIES_TO_NOTHING || error?.response?.data?.code === PAYMENT_ERROR_STRING.TRIAL_ALREADY_TAKEN) {
        showErrorModal = true
        paymentError = error?.response?.data?.code
      }
      const errorMessage = error.response?.data?.message || error.response?.data?.code
      if (errorMessage) {
        message.error(errorMessage)
        return;
      }
      message.error(
        intl.formatMessage({
          id: 'apiErrorMsg',
        }),
      );
    })
    sessionStorage.setItem('subscriptionData', JSON.stringify(subscriptionData));
    if(subscriptionData?.data?.subscription?.url){
      window.open(subscriptionData?.data?.subscription?.url,'_self');
      navigate('/checkout', {
        state: {
          contactAPIData,
          contactFormData,
          selectedProduct,
          selectedProducts,
        },
      });
    } else if (subscriptionData?.data?.paymentIntent) {
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: false,
          subscriptionData: subscriptionData.data,
          paymentIntent: subscriptionData?.data?.paymentIntent,
        };
      });
    } else if (
      subscriptionData?.data?.subscription?.latest_invoice?.status === 'paid' &&
      subscriptionData?.data?.subscription?.latest_invoice?.paid
    ) {
      navigate('/order-complete', {
        replace: true,
        state: {
          subscriptionData: subscriptionData.data.subscription,
          contactData: contactAPIData,
          selectedPriceType: selectedPriceType
        },
      });
    } else if (subscriptionData?.data?.subscription?.id) {
      setPaymentForm((prev) => {
        return {
          ...prev,
          isLoading: false,
          subscriptionData: subscriptionData.data,
        };
      });
    } else {
      navigate('/checkout', {
        state: {
          contactAPIData,
          contactFormData,
          selectedProduct,
          selectedProducts,
          showErrorModal,
          paymentError
        },
      });
    }
  };

  useEffect(() => {
    createSubscription();
  }, []);
  const getStripePaymentElement = (): JSX.Element => {
    if (
      paymentForm?.subscriptionData?.subscription?.latest_invoice
        ?.payment_intent?.client_secret
    ) {
      const subscriptionData = paymentForm?.subscriptionData?.subscription;
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret:
              paymentForm?.subscriptionData?.subscription?.latest_invoice
                ?.payment_intent?.client_secret,
            appearance: PAYMENT_DARK_BLUE_THEME as any,
          }}>
          <PaymentForm
            contactData={contactFormData}
            contactAPIData={contactAPIData}
            stripePromise={stripePromise}
            subscriptionData={subscriptionData}
            selectedProduct={selectedProduct}
            selectedProducts={selectedProducts}
            token={token}
          />
        </Elements>
      );
    } else if (paymentForm?.setupIntent?.client_secret) {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: paymentForm?.setupIntent?.client_secret,
            appearance: PAYMENT_DARK_BLUE_THEME as any,
          }}>
          <PaymentForm
            contactData={contactFormData}
            contactAPIData={contactAPIData}
            stripePromise={stripePromise}
            setupIntent={setupIntent}
            selectedProduct={selectedProduct}
            selectedProducts={selectedProducts}
            clientSecret={paymentForm?.setupIntent?.client_secret}
          />
        </Elements>
      );
    } else if (paymentForm?.paymentIntent?.client_secret) {
      return (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: paymentForm?.paymentIntent?.client_secret,
            appearance: PAYMENT_DARK_BLUE_THEME as any,
          }}>
          <PaymentForm
            contactData={contactFormData}
            contactAPIData={contactAPIData}
            stripePromise={stripePromise}
            selectedProduct={selectedProduct}
            selectedProducts={selectedProducts}
            paymentIntent={paymentForm?.paymentIntent}
          />
        </Elements>
      );
    } else if (!paymentForm.isLoading) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, Not able to checkout for payment"
        />
      );
    }
    return <></>;
  };

  return (
    <MemberShipBackground>
      <Spin
        size="large"
        spinning={paymentForm.isLoading}
        style={{
          top: '26%',
          position: 'fixed',
          width: '100%',
        }}>
        {getStripePaymentElement()}
      </Spin>
    </MemberShipBackground>
  );
};

export default PaymentFormWrapper;
