import {Button, Form, FormInstance, Input} from "antd";
import {createRef, useRef, useState} from "react";
import {checkCouponCodeAPI} from "../../services/StripeProductServices/StripeProductService";
import {getAccSubdomainClassName} from "../../utils/CommonUtils";
import {DisplayText} from "../Common/DisplayText/DisplayText";
const CouponCode = (props: {
  onApplyCouponCode: (couponCode: string) => void;
}) => {
  const couponCodeRef: any = createRef();
  const accountClassName = getAccSubdomainClassName();
  let defaultClassForTheme = '';
  if (window.location.host.includes('localhost')) {
    defaultClassForTheme = 'all-dev-env';
  } else if (window.location.host.includes('.sandbox.foldhealth.io')) {
    defaultClassForTheme = 'all-sandbox-env';
  } else if (window.location.host.includes('.qa.foldhealth.io')) {
    defaultClassForTheme = 'all-qa-env';
  } else if (window.location.host.includes('.dev.foldhealth.io')) {
    defaultClassForTheme = 'all-dev-env';
  } else if (window.location.host.includes('.foldhealth.io')) {
    defaultClassForTheme = 'all-prod-env';
  }

  let appClassNames = 'App';
  if (accountClassName) {
    appClassNames = 'App ' + accountClassName + ' ' + defaultClassForTheme;
  }
  const {onApplyCouponCode} = props;
  const [couponCodeState, setCouponCodeState] = useState({
    couponCodeStatus: '',
  })
  const checkCouponCode = async (couponCode: string) => {
    const couponCodeData = await checkCouponCodeAPI(couponCode).catch(() => {
      setCouponCodeState((prev) => {
        return {
          ...prev,
          couponCodeStatus: 'INVALID'
        }
      });
      onApplyCouponCode('');
    });
    if (couponCodeData?.data?.id) {
      setCouponCodeState((prev) => {
        return {
          ...prev,
          couponCodeStatus: 'SUCCESS'
        }
      });
      onApplyCouponCode(couponCode);
    } else {
      onApplyCouponCode('');
      setCouponCodeState((prev) => {
        return {
          ...prev,
          couponCodeStatus: 'INVALID'
        }
      })
    }
  }
  return (
    <div className={appClassNames}>
      <Form
        autoComplete="off"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          padding: 16
        }}>
        <Form.Item
          name="code">
          <Input ref={couponCodeRef as any} autoComplete="off" placeholder="Coupon Code" />
          {
            couponCodeState.couponCodeStatus === 'INVALID' &&
            <div className="ant-form-item-explain-error">Invalid coupon code</div>
          }
          {
            couponCodeState.couponCodeStatus === 'SUCCESS' &&
            <div className="ant-form-item-explain-success">Coupon code is valid</div>
          }
        </Form.Item>
        <Button
          shape={'round'}
          onClick={() => {
            const couponCode = couponCodeRef?.current?.input?.value || ''
            if (couponCode) {
              checkCouponCode(couponCode);
            }
          }}
          className="fh-membership-checkout"
          type="primary">
          Apply
        </Button>
      </Form>
    </div>
  );
};

export default CouponCode;
