import {LOCALES} from '../locales/locales';

const ENGLISH = {
  [LOCALES.ENGLISH]: {
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    email: 'Email',
    emailOrPhoneNumber: 'Email/Phone number',
    address: 'Address',
    city: 'City',
    state: 'State',
    phoneNumber: 'Phone Number',
    zipcode: 'Zipcode',
    delete: 'Delete',
    checkout: 'Checkout',
    billingAddress: 'Billing Address',
    paymentDetails: 'Payment Details',
    stateName: 'State',
    countryName: 'Country',
    paymentMethodNotPresent: 'Currently payment method is not present',
    otpEmailSend: 'OTP send to your email. Can you please check you email?',
    paymentInitFailed: 'Payment initialization failed',
    orderSummary: 'Order Summary',
    orderCompletedSuccessFully: 'Order placed!',
    cancel: 'Cancel',
    send: 'Send',
    confirm: 'Confirm',
    close: 'Close',
    decline: 'Decline',
    accept: 'Accept',
    proposeNewTime: 'Propose New Time',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    errorSendingOtp:
      'Error while sending otp. Please try again after sometime.',
    successSendingOtp: 'OTP sent successfully',
    apiErrorMsg: 'Something went wrong. Please try again later',
    buyNow: 'Buy Now',
    gender: 'Gender',
    sexAtBirth: 'Sex at birth',
    dob: 'Date Of Birth',
    tokenExpired: 'Membership token expired',
    couponCode: 'Coupon code',
    applyCouponCode: 'Apply coupon code (case sensitive)',
    details: 'Details',
    membership: 'Membership',
    total: 'Total',
    price: 'Price',
    discountedAmount: 'Discounted Amount',
    payment: 'Payment',
    amount: 'Amount',
    oneTime: 'One time',
    howDidYouHearAboutUs: 'How did you hear about us',
    errorGettingAttributeId: 'Error while fetching attribute data',
    errorAttributeNotFound: 'Error while fetching attribute data not found',
  },
};

export default ENGLISH;
