import axios from 'axios';
import {IAccountSubdomainMap} from '../../components/MainPage/IAccountSubDomain';
import {CRM_BASE_URL} from '../../constants/UrlConfigs';
import {
  getMembershipToken,
  getPublicApiAccessToken,
} from '../../utils/CommonUtils';

export default class BaseService {
  private static shared: BaseService;
  public axios;
  private accountData: IAccountSubdomainMap = {} as any;
  private token: string = '';
  constructor(tempAccountData?: IAccountSubdomainMap) {
    if (BaseService.shared) {
      throw new Error('Use BaseService.BaseServiceInstance instead of new.');
    }
    if (tempAccountData) {
      this.accountData = tempAccountData;
    }
    this.axios = axios.create({
      baseURL: `${CRM_BASE_URL}`,
      headers: {
        'Content-type': 'application/json',
        'token-type': 'Bearer',
        // api_access_token: getPublicApiAccessToken(),
      },
    });

    this.axios.interceptors.request.use(
      async (config) => {
        const membershipAccessToken = getMembershipToken() || '';
        config.headers = {
          'Content-type': 'application/json',
          // api_access_token: getPublicApiAccessToken(),
          accountId: this?.accountData?.accountId || '',
          accountUuid: this?.accountData?.accountToSubdomain?.uuid || '',
          'account-id': this?.accountData?.accountId || '',
          'account-uuid': this?.accountData?.accountToSubdomain?.uuid || '',
          'membership-access-token': membershipAccessToken || this.token,
          ...config.headers,
          subdomain: this?.accountData?.subDomain || '',
        };
        config.headers.subdomain = this?.accountData?.subDomain || '';
        return config;
      },
      (error) => {
        console.log(error);
      },
    );

    BaseService.shared = this;
  }
  static getSharedInstance(
    accountData?: IAccountSubdomainMap,
    token?: string,
  ): BaseService {
    if (BaseService.shared && accountData && Object.keys(accountData)?.length) {
      BaseService.shared.accountData = accountData as any;
    }
    if (BaseService.shared && token) {
      BaseService.shared.token = token;
    }
    return (
      BaseService.shared ?? (BaseService.shared = new BaseService(accountData))
    );
  }
}
