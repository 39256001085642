import {Card, Image, Layout, message, Row} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import Title from 'antd/lib/typography/Title';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DEFAULT_LOGO_URL} from '../../constants/StringConst';
import {COLOR_CONST} from '../../constants/ThemeColorConst';
import {Context} from '../../context/CommonContext';
import {
  getAllBillingListFromAPI,
  getAllBillingSubscriptionListFromAPI,
  getAllMembershipListFromAPI,
} from '../../services/StripeProductServices/StripeProductService';
import {
  getProductId,
  getProductPlan,
  getQueryParams,
  getQueryParamValue,
  getBackGroundColorBottom,
  getBackGroundColorTop
} from '../../utils/CommonUtils';
import {IAccountThemeAdditionalAttr} from '../MainPage/IAccountSubDomain';
import BillingCard from './BillingCard/BillingCard';
import {IBuyNowProductData, IMembershipState} from './interface';
import MemberShipCard from './MembershipCard/MemberShipCard';
import {useIntl} from 'react-intl';

const {Meta} = Card;
const Membership = () => {
  const navigate = useNavigate();
  const commonData = useContext(Context);
  const accountThemeData = commonData.accountThemeData;
  const accountData = commonData.accountData;
  let additionalAttributes: IAccountThemeAdditionalAttr =
  accountThemeData.additionalAttributes || ('{}' as any);
  const productId = getProductId();
  const plan = getProductPlan();
  const url = new URL(window.location.href);
  const productid = url.searchParams.get('productid');
  const intl = useIntl();
  if (additionalAttributes) {
    additionalAttributes = JSON.parse(additionalAttributes as any);
  }
  const [membershipState, setMembershipState] = useState<IMembershipState>({
    membershipList: [],
    subscriptionList: [],
    billingPlansList: [],
  });

  const contentObject = {
    imageUrl: additionalAttributes?.attachment?.logo?.url || DEFAULT_LOGO_URL,
    title: '',
    description: '',
  };

  const foldCustAccount = getQueryParamValue('foldCustAccountUuid');
  const foldCustAccountUuid = foldCustAccount
    ? foldCustAccount
    : accountData?.accountToSubdomain?.uuid;

  const getAllBillingList = async () => {
    const membershipListResp = await getAllBillingListFromAPI();
    if (membershipListResp?.data) {
      const response = membershipListResp?.data;

      const subscriptionArray = await getAllBillingSubscriptionList();
      setMembershipState((prev) => {
        return {
          ...prev,
          billingPlansList: [...response.data],
          subscriptionList: [...subscriptionArray],
        };
      });
    }
  };

  const getAllMembershipList = async () => {
    const params = {
      productId: productid
    }
    try {
      const membershipListResp = await getAllMembershipListFromAPI(params);
      if (membershipListResp?.data?.data) {
        const membershipArray = membershipListResp?.data?.data?.filter(
          (singleMembershipData) => {
            return (
              singleMembershipData.active && singleMembershipData.prices.length
            );
          },
        );
        if (
          membershipArray?.length === 1 &&
          membershipArray?.[0].prices?.length === 1
        ) {
          const selectedPrice = membershipArray?.[0].prices[0];
          const preSelectedProduct = membershipArray?.[0];
          onBuyNowClick({
            selectedPrice: selectedPrice as any,
            selectedProduct: preSelectedProduct,
          });
          return;
        }
        setMembershipState((prev) => {
          return {
            ...prev,
            membershipList: [...membershipArray],
          };
        });

        if (productId && plan) {
          const preSelectedProduct = membershipArray?.find(
            (value) => value?.id == productId,
          );
          if (preSelectedProduct) {
            const selectedPrice = preSelectedProduct?.prices?.find(
              (value) => value?.id == plan,
            );
            if (selectedPrice) {
              onBuyNowClick({
                selectedPrice: selectedPrice as any,
                selectedProduct: preSelectedProduct,
              });
            }
          }
        }
      }
    } catch (err: any) {
      message.error(err?.response?.data?.message || intl.formatMessage({id: 'apiErrorMsg'}),);
    }
  };

  const getAllBillingSubscriptionList = async () => {
    const billingListResp = await getAllBillingSubscriptionListFromAPI(
      foldCustAccountUuid,
    );
    if (billingListResp?.data?.data) {
      const membershipArray = billingListResp?.data?.data;

      return membershipArray;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (foldCustAccount) {
      getAllBillingList();
    } else {
      getAllMembershipList();
    }
  }, []);

  const onBuyNowClick = (productAndPriceData: IBuyNowProductData) => {
    const id = productAndPriceData.selectedProduct.id;
    const subscriptionlist = membershipState.subscriptionList;

    const subscriptionArray = subscriptionlist.filter(
      (singleMembershipData) => {
        return (
          singleMembershipData.metadata?.foldCustAccountUuid ===
            foldCustAccountUuid &&
          singleMembershipData.items.data[0].plan?.product === id
        );
      },
    );
    if (subscriptionArray.length > 0) {
      message.error(
        `${productAndPriceData.selectedProduct.name} already purchased`,
      );
    } else {
      const queryString = getQueryParams();
      let navigationUrl = '/checkout';
      if (queryString) {
        navigationUrl = '/checkout/?' + queryString;
      }

      navigate(navigationUrl, {
        state: {
          selectedProduct: {
            ...productAndPriceData,
          },
          selectedProducts: {
            ...productAndPriceData,
          },
          redirectToPayment: (productId && plan) ? true : false
        },
      });
    }
  };
  return (
    <Content
      style={{
        height: '100vh',
      }}>
      <Layout style={{background: 'transparent'}}>
        <Layout
          style={{
            height: '100%',
            position: 'fixed',
            width: '100%',
            zIndex: -1,
          }}>
          <Content
            style={{
              height: '50%',
              background: getBackGroundColorTop(),
            }}>
            <Content
              style={{
                background: getBackGroundColorBottom(),
                height: '100%',
              }}></Content>
          </Content>
          <Content style={{height: '50%', background: getBackGroundColorTop()}}>
            {/* Content2 */}
          </Content>
        </Layout>
        <Content style={{width: '90%', margin: 'auto', padding: 100}}>
          <Row
            style={{
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Image preview={false} width={100} src={contentObject.imageUrl} />
          </Row>
          <Title
            style={{
              color: COLOR_CONST.titleColor,
              fontSize: '1.3rem',
              fontWeight: 700,
              marginBottom: 0,
              marginTop: 16,
            }}>
            {contentObject.title}
          </Title>
          <Title
            style={{
              color: COLOR_CONST.descriptionColor,
              fontSize: '1rem',
              fontWeight: 400,
              marginBottom: 0,
              marginTop: 16,
            }}>
            {contentObject.description}
          </Title>
          <div
            className="fh-card-wrapper"
            style={{
              height: 'fix-content',
              marginTop: 70,
              justifyContent: 'center',
            }}>
            {!foldCustAccount &&
              membershipState.membershipList.map((singleProduct, index) => {
                const ind = index + 1;
                return (
                  <div key={index}>
                    <MemberShipCard
                      index={ind + 1}
                      membershipData={singleProduct}
                      onBuyNowClick={onBuyNowClick}
                    />
                  </div>
                );
              })}
            {foldCustAccount &&
              membershipState.billingPlansList.map((singlePlan, index) => {
                const ind = index + 1;
                if (singlePlan.showProduct) {
                  return (
                    <div key={index}>
                      <BillingCard
                        index={ind + 1}
                        membershipData={singlePlan}
                        onBuyNowClick={onBuyNowClick}
                      />
                    </div>
                  );
                }
              })}
          </div>
        </Content>
      </Layout>
    </Content>
  );
};

export default Membership;
