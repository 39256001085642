export const PAYMENT_DARK_BLUE_THEME = {
  theme: 'night',
  variables: {
    fontFamily: 'Sohne, system-ui, sans-serif',
    fontWeightNormal: '500',
    borderRadius: '8px',
    textColor: '#F1BE3E',
    colorBackground: '#316A58',
    colorPrimary: '#F1BE3E',
    colorError: '#F1BE3E',
    colorPrimaryText: '#1A1B25',
    colorText: '#F1BE3E',
    colorTextSecondary: '#F1BE3E',
    colorTextPlaceholder: '#F1BE3E',
    colorIconTab: '#F1BE3E',
    colorLogo: 'dark',
  },
  rules: {
    '.Input, .Block': {
      backgroundColor: '#316A58',
      color: '#F1BE3E',
      textColor: '#F1BE3E',
      border: '1.5px solid var(--colorPrimary)',
      borderColor: '#F1BE3E',
    },
  },
};
